/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import { isObjectEmpty } from "api/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { isMobile } from "react-device-detect";

function OrderInfo({
  document,
  handleDelete,
  handleDownload,
  handleRefresh,
  loading,
  lookupUsers,
  ownerActions,
  disableSign,
  accessRead,
  handleClickDetails,
}) {
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [confirmed, setConfirmed] = useState({});
  const [signatureResp, setSignatureResp] = useState({});
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, []);

  const getFrontendUrl = () => {
    const queryParameters = new URLSearchParams({
      data_id: document.data_id,
      sender: document.sender,
      receiver: document.receiver,
      hash: document.hash,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const handleSign = async () => {
    // Deletion parameters
    const params = {
      parentId: document._id,
      parentType: "PACKAGE",
      access: document.access,
      frontendUrl: getFrontendUrl(),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setShow(true);
      setVariant("error");
      setMessage(e.message);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setMessage(t("document-successfully-signed"));
          setSignatureResp(responseData);
          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
      }
    } catch (e) {
      console.error("Error downloading package");
      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  useEffect(() => {
    const checkConfirmed = (signatures, accessRights) => {
      if (accessRights.length === 0 && signatures.length === 0) {
        return {
          status: "stored",
          mobile: t("stored"),
          description: t("this-document-is-stored"),
        };
      }
      if (accessRights.length === 0 && signatures.length > 0) {
        return {
          status: "signed-owner",
          mobile: t("signed-by-owner"),
          description: t("this-document-is-signed-by-me"),
        };
      }
      const signSet = new Set();
      signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

      const accessSet = new Set();
      accessRights
        .filter((access) => access.type === "SIGN")
        .map((acc) => accessSet.add(acc.email));

      if (signSet.size === 0 && accessSet.size === 0) {
        return {
          status: "stored",
          mobile: t("stored"),
          description: t("this-document-is-stored"),
        };
      }
      if (accessSet.size === 0 && signSet.size > 0) {
        return {
          status: "signed-owner",
          mobile: t("signed-by-owner"),
          description: t("this-document-is-signed-by-the-owner"),
        };
      }
      if (
        (signSet.size === accessSet.size &&
          !accessSet.has(userProfile.email) &&
          !signSet.has(userProfile.email) &&
          accessRights.filter((acc) => acc.email === userProfile.email).length === 0) ||
        signSet.size === accessSet.size + 1
      ) {
        return {
          status: "signed",
          mobile: t("signed"),
          description: t("this-document-is-signed-and-signatures-are-written-to-blockchain"),
        };
      }
      return {
        status: "not-signed",
        mobile: t("not-signed"),
        description: t("this-document-is-not-yet-fully-signed"),
      };
      // eslint-disable-next-line no-else-return
    };

    if (!isObjectEmpty(document)) {
      const resp = checkConfirmed(document?.signatures, document?.accessRights);
      setConfirmed(resp);
    }
  }, [document, signatureResp]);

  const [openFoodModal, setOpenFoodModal] = useState(false);

  const handleOpenFoodFormModal = () => {
    setOpenFoodModal(true);
  };
  const handleCloseFoodModal = () => {
    setOpenFoodModal(false);
  };

  const [intReference, setIntReference] = useState("");
  const handleChangeReference = (e) => {
    setIntReference(e.target.value);
  };

  const handleUpdateSign = async () => {
    if (intReference.length > 0) {
      const foodMetadata = document.metadata.find((elem) => elem.name === "formData");
      const parsedMetadata = JSON.parse(foodMetadata.metadata);
      parsedMetadata.intReferenceNumberRec = intReference;
      const updatedParams = {
        parentId: document._id,
        parentType: "PACKAGE",
        metadataId: foodMetadata?._id,
        metadata: parsedMetadata,
      };
      try {
        await ApiService.updateMetadata(updatedParams);
      } catch (e) {
        console.error(e);
      }
    }
    handleSign();
    handleCloseFoodModal();
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseOpenDelete = () => {
    setOpenDelete(false);
  };

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDAvatar bgColor="info">
                <Icon fontSize="lg">picture_as_pdf</Icon>
              </MDAvatar>
            </MDBox>
            <MDBox lineHeight={1} style={{ wordBreak: "break-word" }}>
              <MDTypography variant="h6" fontWeight="medium">
                {!loading && !isObjectEmpty(document) ? (
                  document.title
                ) : (
                  <Skeleton variant="text" sx={{ width: "100px", fontSize: "1rem" }} />
                )}
              </MDTypography>
              <MDBox mb={2}>
                <MDTypography variant="button" color="text">
                  {confirmed.description}
                </MDTypography>
              </MDBox>
              <MDBadge
                variant="gradient"
                color={
                  // eslint-disable-next-line no-nested-ternary
                  confirmed.status === "stored"
                    ? "success"
                    : confirmed.status === "signed-owner" || confirmed.status === "signed"
                    ? "primary"
                    : "error"
                }
                size="xs"
                badgeContent={isMobile ? confirmed.mobile : confirmed.description}
                container
              />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
          <Tooltip title={ownerActions ? t("view-and-add-metadata") : t("read-access")}>
            <span>
              <MDButton
                variant="text"
                color="primary"
                size="small"
                iconOnly
                disabled={
                  document.status === "EXPIRED" ||
                  document.status === "DELETED" ||
                  accessRead ||
                  !ownerActions
                }
                onClick={handleClickDetails}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              </MDButton>
            </span>
          </Tooltip>
          {ownerActions && (
            <MDButton
              variant="text"
              color="dark"
              size="small"
              onClick={handleOpenDelete}
              disabled={document.status === "EXPIRED" || document.status === "DELETED"}
            >
              {t("delete")}
            </MDButton>
          )}
          <MDButton
            style={{ marginRight: "10px" }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleDownload}
            disabled={document.status === "EXPIRED" || document.status === "DELETED"}
          >
            {t("download")}
          </MDButton>
          <Tooltip
            title={
              accessRead
                ? t("read-access")
                : disableSign
                ? t("you-already-signed-this-document")
                : t("sign-document")
            }
          >
            <span>
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                disabled={
                  document.status === "EXPIRED" ||
                  document.status === "DELETED" ||
                  disableSign ||
                  accessRead
                }
                onClick={
                  document.flags?.find((flag) => flag.name === "FOOD_FORM")
                    ? handleOpenFoodFormModal
                    : handleSign
                }
              >
                {t("sign")}
              </MDButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <MDSnackbar
        icon="notifications"
        title={t("sign-document")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
      <Dialog open={openDelete} onClose={handleCloseFoodModal}>
        <DialogTitle>{t("delete-file")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>{t("are-you-sure-you-want-to-delete-this-file")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenDelete}>{t("cancel")}</Button>
          <Button onClick={handleDelete}>{t("yes")}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openFoodModal} onClose={handleCloseFoodModal}>
        <DialogTitle>{t("receiving-food-business-operator")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>
            {t(
              "in-order-to-finish-the-registration-form-enter-the-internal-reference-number-and-sign-the-form"
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="token"
            label={`II.1 ${t("internal-reference-number")}`}
            fullWidth
            value={intReference}
            variant="standard"
            onChange={handleChangeReference}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFoodModal}>{t("cancel")}</Button>
          <Button onClick={handleUpdateSign}>{t("sign")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Typechecking props for the ProfilesList
OrderInfo.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};
export default OrderInfo;
