/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

function Signatures({ title, signatures, shadow, loading, lookupUsers }) {
  const { t } = useTranslation();
  const renderProfiles = signatures
    ? signatures?.map((sign, index) => (
        <MDBox key={sign._id} component="li" display="flex" alignItems="center" mb={2}>
          <MDBox
            key={`${sign._id}-${index}`}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {lookupUsers[sign.sender]}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              {`${t("status")}: ${t("signed")}, ${sign.transactionStatus.toLowerCase()} ${t(
                "on-blockchain"
              )}`}
            </MDTypography>
          </MDBox>
          <MDBox ml="auto" key={`${sign._id}-${index}-link`}>
            <MDButton
              component={Link}
              href={`https://whatsonchain.com/tx/${sign.transactionId}`}
              variant="text"
              color="primary"
              target="_blank"
              rel="noreferrer"
            >
              <Icon>link</Icon>
            </MDButton>
          </MDBox>
        </MDBox>
      ))
    : loading &&
      [{}].map((sign, index) => (
        <MDBox key={sign._id} component="li" display="flex" alignItems="center" mb={2}>
          <MDBox
            key={`${sign._id}-${index}`}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              <Skeleton variant="text" sx={{ width: "100px", fontSize: "0.875rem" }} />
            </MDTypography>
            <MDTypography variant="caption" color="text">
              <Skeleton variant="text" sx={{ width: "100px", fontSize: "0.75rem" }} />
            </MDTypography>
          </MDBox>
        </MDBox>
      ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox key="signatures-key-1">
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox pt={2} key="signatures-key-2">
        <MDBox
          key="signatures-key-3"
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
Signatures.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
Signatures.propTypes = {
  title: PropTypes.string.isRequired,
  signatures: PropTypes.arrayOf(PropTypes.any).isRequired,
  shadow: PropTypes.bool,
};

export default Signatures;
