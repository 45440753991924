/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/account/components/FormField";
import { useMaterialUIController } from "context";
import ApiService from "api/ApiService";
import Skeleton from "@mui/material/Skeleton";
import { isObjectEmpty } from "api/utils";
import { useTranslation } from "react-i18next";

function Bill({ profile, loading }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();

  const [showEditData, setShowEditData] = useState(false);
  const [userName, setUserName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userZip, setUserZip] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [userGovID, setUserGovID] = useState("");

  useEffect(() => {
    if (!isObjectEmpty(profile)) {
      setUserAddress(profile.currentAddress || "");
      setUserZip(profile.postalCode || "");
      setUserCity(profile.city || "");
      setUserLocation(profile.country || "");
      setUserGovID(profile.govID || "");
    }
  }, [profile]);

  const handleSetLocation = (e) => {
    setUserLocation(e.target.value);
  };

  const handleSetAddress = (e) => {
    setUserAddress(e.target.value);
  };

  const handleSetCity = (e) => {
    setUserCity(e.target.value);
  };

  const handleSetZip = (e) => {
    setUserZip(e.target.value);
  };

  const handleSetGovID = (e) => {
    setUserGovID(e.target.value);
  };

  const handleUpdateProfile = async () => {
    const requestData = {
      userId: profile._id,
    };
    if (userName.length > 0 && userName !== profile.name) {
      requestData.name = userName;
    }
    if (userAddress.length > 0 && userAddress !== profile.address) {
      requestData.currentAddress = userAddress;
    }
    if (userLocation.length > 0 && userLocation !== profile.country) {
      requestData.country = userLocation;
    }
    if (userCity.length > 0 && userCity !== profile.city) {
      requestData.city = userCity;
    }
    if (userZip.length > 0 && userZip !== profile.zip) {
      requestData.postalCode = userZip;
    }
    if (userGovID.length > 0 && userGovID !== profile.govID) {
      requestData.govID = userGovID;
    }

    try {
      await ApiService.updateUser(requestData);
    } catch (e) {
      console.error(e);
    }

    const { signal } = new AbortController();

    try {
      const userProfile = await ApiService.loginRequired(signal, false);
      setUserName(userProfile.name);
      setUserAddress(userProfile.currentAddress);
      setUserCity(userProfile.city);
      setUserLocation(userProfile.country);
      setUserZip(userProfile.postalCode);
      setUserGovID(userProfile.govID);
      setShowEditData(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleEditData = () => {
    setShowEditData(true);
  };

  const handleCloseEditData = () => {
    setShowEditData(false);
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {userName}
          </MDTypography>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {showEditData ? (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleCloseEditData}
              >
                <Icon>close</Icon>
                {t("cancel")}
              </MDButton>
            ) : (
              <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEditData}>
                <Icon>edit</Icon>
                {t("edit")}
              </MDButton>
            )}
          </MDBox>
        </MDBox>
        {showEditData ? (
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} sm={12}>
                <FormField
                  label={t("name")}
                  placeholder={userName}
                  value={userName}
                  onChange={handleSetUserName}
                />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <FormField
                  label="GovID"
                  placeholder={userGovID}
                  value={userGovID}
                  onChange={handleSetGovID}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("address")}
                  placeholder={userAddress}
                  value={userAddress}
                  onChange={handleSetAddress}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("ZIP")}
                  placeholder={userZip}
                  value={userZip}
                  onChange={handleSetZip}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("city")}
                  placeholder={userCity}
                  value={userCity}
                  onChange={handleSetCity}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  label={t("country")}
                  placeholder={userLocation}
                  value={userLocation}
                  onChange={handleSetLocation}
                />
              </Grid>

              <MDBox pt={4} pl={2}>
                <MDButton variant="gradient" color="primary" onClick={handleUpdateProfile}>
                  {t("update-profile")}
                </MDButton>
              </MDBox>
            </Grid>
          </MDBox>
        ) : (
          <>
            {/* <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {`${t("company-name")}   `}
                <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  {profile.organizations ? (
                    profile.organizations[0]?.name
                  ) : (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox> */}
            {/* <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {`${t("email-address")}   `}
                <MDTypography variant="caption" fontWeight="medium">
                  {profile.email || (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox> */}
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {t("government-id")}
                <MDTypography variant="caption" fontWeight="medium" style={{ marginLeft: "1rem" }}>
                  {!userGovID && loading ? (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  ) : (
                    userGovID
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {t("address")}
                <MDTypography variant="caption" fontWeight="medium" style={{ marginLeft: "1rem" }}>
                  {!userAddress && loading ? (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  ) : (
                    userAddress
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {t("ZIP")}
                <MDTypography variant="caption" fontWeight="medium">
                  {!userZip && loading ? (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  ) : (
                    userZip
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {t("city")}
                <MDTypography variant="caption" fontWeight="medium">
                  {!userCity && loading ? (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  ) : (
                    userCity
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox>

            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                {t("country")}
                <MDTypography variant="caption" fontWeight="medium">
                  {!userLocation && loading ? (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "100%" }} />
                  ) : (
                    userLocation
                  )}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </>
        )}
      </MDBox>
    </MDBox>
  );
}

export default Bill;
