/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import { formatDateTime } from "api/utils";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Transaction from "layouts/pages/account/billing/components/Transaction";
import TransactionSkelet from "../Transaction/TransactionSkelet";

function Transactions({ loading, invoices }) {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {t("your-top-ups")}
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          {/* <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox> */}
          {/* <MDTypography variant="button" color="text" fontWeight="regular">
            01 - 15 January 2023
          </MDTypography> */}
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        {invoices.length > 0 && (
          <MDBox mb={2}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="bold"
              textTransform="uppercase"
            >
              {t("newest")}
            </MDTypography>
          </MDBox>
        )}

        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {loading ? (
            <>
              <TransactionSkelet />
              <TransactionSkelet />
              <TransactionSkelet />
            </>
          ) : (
            <>
              {invoices
                .filter((inv) => inv.paymentMethod === "CREDIT CARD")
                .slice(0, 3)
                .map((invoice) => (
                  <Transaction
                    color="primary"
                    icon="expand_less"
                    name={t("top-up")}
                    description={formatDateTime(invoice.updatedAt)}
                    value={`+ ${invoice.amount}€`}
                  />
                ))}
            </>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Transactions;
