import moment from "moment";

export const formatDate = (date) => moment(date).format("DD.MM.YYYY");
export const formatDateTime = (dateTime, endLine = false) => {
  if (endLine) {
    return moment(dateTime).format("DD.MM.YYYY [\n]HH:mm");
  }
  return moment(dateTime).format("DD.MM.YYYY HH:mm");
};

export const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};
