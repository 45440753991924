import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Skeleton from "@mui/material/Skeleton";

function InvoiceSkelet() {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          <Skeleton variant="text" sx={{ width: "200px", fontSize: "inherit" }} />
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          <Skeleton variant="text" sx={{ width: "200px", fontSize: "inherit" }} />
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text">
          <Skeleton variant="text" sx={{ width: "50px", fontSize: "inherit" }} />
        </MDTypography>
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <MDButton>
            <Icon fontSize="small">picture_as_pdf</Icon>
            <MDTypography variant="button" fontWeight="bold">
              &nbsp;PDF
            </MDTypography>
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default InvoiceSkelet;
