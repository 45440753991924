/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, createRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import "./style.css";
import MDSnackbar from "components/MDSnackbar";
import { isObjectEmpty } from "api/utils";
import { isMobile } from "react-device-detect";
import { Tooltip } from "@mui/material";
import Icon from "@mui/material/Icon";
import Cropper from "react-cropper";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";

function ProductImage({ profile }) {
  const { t } = useTranslation();

  const [upload, setUpload] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState({});
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [deleteResp, setDeleteResp] = useState({});
  const [cropImage, setCropImage] = useState(false);
  const cropperRef = useRef(null);
  const [croppedFile, setCroppedFile] = useState(null);

  const fileInput = createRef();
  const handleClose = () => {
    setShow(false);
  };

  const onCrop = async () => {
    const cropper = cropperRef.current?.cropper;
    const cropUrl = cropper.getCroppedCanvas().toDataURL();
    const response = await fetch(cropUrl);
    const blob = await response.blob();
    const file = new File([blob], uploadedFile.name, {
      type: uploadedFile.type,
      lastModified: new Date(),
    });
    setCroppedFile(file);
  };

  const uploadSignature = async (extract, width = "400", height = "400") => {
    try {
      const resp = await ApiService.upsertUserImage({
        userId: profile._id,
        documentType: "SIGNATURE",
        encryptDocument: true,
        resizeImage: true,
        scaleImage: true,
        file: croppedFile,
        imageWidth: width,
        imageHeight: height,
        extractImageText: extract,
      });
      setUploadResponse(resp);
      setUploadedFile(null);
      setCropImage(false);
      setUpload(false);
    } catch (e) {
      console.error(e);
    }
  };

  const [fileToCrop, setFileToCrop] = useState("");
  useEffect(() => {
    const getUserSignature = async () => {
      try {
        const sigRes = await ApiService.readUserDocument({
          userId: profile._id,
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: ApiService.documentTypes.signature,
        });
        setImagePreviewUrl(`data:image/png;base64, ${sigRes.contents}`);
        setUpload(false);
      } catch (e) {
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && getUserSignature();
  }, [uploadResponse, deleteResp, profile]);

  const handleDelete = async () => {
    try {
      const resp = await ApiService.deleteUserDocument({
        userId: profile._id,
        documentCriteria: ApiService.documentCriteria.documentType,
        documentFilter: "SIGNATURE",
      });
      setDeleteResp(resp);
      setUpload(true);
      setMessage(t("signature-deleted"));
      setVariant("primary");
      setShow(true);
    } catch (e) {
      setMessage(e.message);
      setVariant("danger");
      setShow(true);
    }
  };

  const handleCancelCrop = () => {
    setUpload(true);
    setCropImage(false);
    setUploadedFile(null);
  };
  return (
    <>
      <Card
        sx={
          upload
            ? {}
            : {
                // "&:hover .card-header": {
                //   transform: "translate3d(0, -50px, 0)",
                // },
              }
        }
      >
        <MDBox position="relative" borderRadius="lg" mt={-3} mx={2} className="card-header">
          {upload ? (
            <>
              <MDBox
                borderRadius="lg"
                sx={{ background: "#017f98" }}
                shadow="sm"
                width="100%"
                height="100%"
                position="relative"
                zIndex={10}
                mb={2}
              >
                <div style={{ textAlign: "center" }}>
                  <div className="picture1">
                    <label htmlFor="files" style={{ display: "block", color: "#fff" }}>
                      <MDTypography variant="h6" color="light">
                        {isMobile ? t("upload-or-take-a-picture") : t("upload-or-drop-file")}
                      </MDTypography>
                      {isMobile && (
                        <div style={{ textAlign: "center" }}>
                          <Icon>camera_alt</Icon>
                        </div>
                      )}
                    </label>
                    <input
                      style={{
                        cursor: "pointer",
                        display: "block",
                        height: "100%",
                        left: "0",
                        opacity: "0",
                        position: "absolute",
                        top: "0",
                        width: "100%",
                      }}
                      type="file"
                      id="files"
                      accept="image/png, image/gif, image/jpeg"
                      ref={fileInput}
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          setUploadedFile(selectedFile);
                          const objectURL = URL.createObjectURL(selectedFile);

                          setFileToCrop(objectURL);
                          setUpload(false);
                          setCropImage(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </MDBox>
              <MDTypography variant="caption">{uploadedFile ? uploadedFile.name : ""}</MDTypography>
            </>
          ) : cropImage ? (
            <Cropper
              src={fileToCrop}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              initialAspectRatio={16 / 9}
              guides
              crop={onCrop}
              ref={cropperRef}
            />
          ) : (
            <MDBox
              component="img"
              src={imagePreviewUrl}
              alt={t("product-image")}
              borderRadius="lg"
              shadow="sm"
              width="100%"
              height="100%"
              position="relative"
              zIndex={10}
              mb={2}
            />
          )}
        </MDBox>
        <MDBox textAlign="center" pt={2} pb={3} px={3}>
          {!upload && !cropImage && (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
              position="relative"
              zIndex={1}
            >
              <MDButton variant="outlined" color="dark" size="small" onClick={handleDelete}>
                {t("remove")}
              </MDButton>
            </MDBox>
          )}
          {cropImage && (
            <>
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
                position="relative"
                zIndex={1}
              >
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={() => uploadSignature(false)}
                >
                  {t("save")}
                </MDButton>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
                position="relative"
                zIndex={1}
              >
                <Tooltip title={t("picture-is-uploaded-and-signature-auto-extracted")}>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => uploadSignature(true, "300", "150")}
                  >
                    {t("save-extract")}
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
                position="relative"
                zIndex={1}
              >
                <MDButton variant="outlined" color="dark" size="small" onClick={handleCancelCrop}>
                  {t("cancel")}
                </MDButton>
              </MDBox>
            </>
          )}

          <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
            {t("signature")}
          </MDTypography>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("signature")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default ProductImage;
