import { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import image from "assets/images/image.png";
import { isMobile } from "react-device-detect";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ApiService from "api/ApiService";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import UploadFile from "./components/UploadFile";
import PDFPreview from "./PDFPreview";

function AddFilePage() {
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState({});
  const [file, setFile] = useState(null);
  const [fileRemoved, setFileRemoved] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [docType, setDocType] = useState("");
  const [pic, setPic] = useState("");
  const [report, setReport] = useState(null);
  const [scale, setScale] = useState(1.0);
  const handleZoomIn = () => {
    setScale((prev) => prev + 0.2);
  };

  const handleZoomOut = () => {
    setScale((prev) => prev - 0.2);
  };
  const handleInputDisabled = (bool) => {
    setShowRemove(bool);
  };

  const handleSetFile = (file, type) => {
    if (type === "picture") {
      setPic(file);
      setFile(null);
      return setDocType(type);
    }
    if (type === "report") {
      setReport(file);
      return setDocType(type);
    }
    setFile(file);
    if (file.type === "application/pdf") {
      return setDocType("pdfFile");
    }
    return setDocType(type);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPic("");

    setFileRemoved(true);
  };

  const handleSetRemovefile = (bool) => {
    setFileRemoved(bool);
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={5}>
            <Card id="add-file">
              <UploadFile
                profile={userProfile}
                handleAction={handleSetFile}
                fileRemoved={fileRemoved}
                handleSetRemovefile={handleSetRemovefile}
                handleInputDisabled={handleInputDisabled}
                handleRemoveFile={handleRemoveFile}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} xl={7}>
            {file === null && pic.length === 0 ? (
              <MDBox sx={{ textAlign: "center", display: { xs: "none", md: "block" } }}>
                <MDTypography variant="h4" fontWeight="bold">
                  {t("scan-sign-and-store-your-documents")}
                </MDTypography>
                <MDBox pt={5}>
                  <MDTypography variant="body2">
                    {t(
                      "a-fast-easy-and-secure-way-to-scan-sign-and-store-your-documents-on-the-public-blockchain-in-the-most-secure-legal-and-efficient-way"
                    )}
                  </MDTypography>
                </MDBox>
                <MDBox
                  component="img"
                  src={image}
                  alt={t("uploaded-image")}
                  borderRadius="lg"
                  shadow="sm"
                  height="auto"
                  position="relative"
                  maxWidth="100%"
                  zIndex={10}
                  mt={10}
                />
              </MDBox>
            ) : (
              <>
                {!showRemove && (
                  <Tooltip title={t("remove-file")} placement="top">
                    <MDButton
                      color="primary"
                      size="small"
                      iconOnly
                      onClick={() => handleRemoveFile()}
                      style={{ float: "right", marginLeft: "5px" }}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                )}
                {!isMobile && (
                  <>
                    <Tooltip title={t("zoom-in")} placement="top">
                      <MDButton variant="outlined" color="info" onClick={handleZoomIn}>
                        <Icon>add</Icon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title={t("zoom-out")} placement="top">
                      <MDButton variant="outlined" color="info" onClick={handleZoomOut}>
                        <Icon>remove</Icon>
                      </MDButton>
                    </Tooltip>
                  </>
                )}

                {file?.type === "application/pdf" && docType === "pdfFile" && !isMobile && (
                  <PDFPreview file={file} scale={scale} />
                )}
                {docType === "report" && !isMobile && <PDFPreview file={report} />}
                {file?.type.startsWith("image") && docType !== "report" && (
                  <MDBox
                    component="img"
                    src={URL.createObjectURL(file)}
                    alt={t("uploaded-image")}
                    borderRadius="lg"
                    shadow="sm"
                    width="100%"
                    height="auto%"
                    position="relative"
                    zIndex={10}
                    mb={2}
                  />
                )}
                {docType === "picture" && (
                  <MDBox
                    component="img"
                    src={pic}
                    alt={t("uploaded-image")}
                    borderRadius="lg"
                    shadow="sm"
                    width="100%"
                    height="auto%"
                    position="relative"
                    zIndex={10}
                    mb={2}
                  />
                )}
                {isMobile && (
                  <MDBox mt={1} mb={1}>
                    <MDTypography variant="caption">
                      {t("please-use-a-web-browser-to-open-the-document")}
                    </MDTypography>
                  </MDBox>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddFilePage;
