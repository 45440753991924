/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DeleteAccount({ orgaizationProfile }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteProfile = async () => {
    try {
      await ApiService.deleteOrganizationSuperuser({
        organizationId: orgaizationProfile._id,
      });
      setMessage(t("profile-deletion-process-started"));
      setVariant("info");
      setShow(true);
      navigate("/");
    } catch (e) {
      console.error(e);
      setMessage(t("error-deleting-profile"));
      setVariant("error");
      setShow(true);
    }
    setOpenModal(false);
  };

  return (
    <>
      {" "}
      <Card id="delete-company">
        <MDBox
          pr={3}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDBox p={3} lineHeight={1}>
            <MDBox mb={1}>
              <MDTypography variant="h5">{t("delete-company-profile")}</MDTypography>
            </MDBox>
            <MDTypography variant="button" color="text">
              {t("permanently-delete-company-profile")}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }} pb={{ xs: 3, sm: 0 }}>
            <MDBox ml={2} mt={{ xs: 1, sm: 0 }}>
              <MDButton
                variant="gradient"
                color="error"
                sx={{ height: "100%" }}
                onClick={handleOpenModal}
              >
                {t("delete")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{t("delete-profile")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>{t("profile-deletion-process-started-0")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseModal}>{t("cancel")}</MDButton>
          <MDButton color="error" variant="contained" onClick={handleDeleteProfile}>
            {t("delete-profile")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title={t("delete-profile")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default DeleteAccount;
