/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import Company from "layouts/pages/account/company";

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import DocumentDetails from "layouts/pages/document-details";
import ReceivedDocumentDetails from "layouts/pages/received-document-details";
import "./i18n";
import { Context } from "context/Context";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [cleanState, setCleanState] = useState(false);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const helperRoutes = [
    {
      type: "collapse",
      name: "Brooklyn Alice",
      key: "brooklyn-alice-1",
      hidden: true,
      collapse: [
        {
          name: "My Profile",
          key: "profile-1",
          route: "/profile/profile-overview",
          component: <Settings />,
        },
        {
          name: "Company profile",
          key: "profile-2",
          route: "/company/company-overview",
          component: <Company />,
        },
        {
          name: "Billing",
          key: "account-1",
          route: "/account/billing",
          component: <Billing />,
        },
        {
          name: "Logout",
          key: "logout-1",
          route: "/authentication/sign-in",
          component: <SignInIllustration />,
        },
      ],
    },
  ];

  const updatedRoutes = helperRoutes.concat(routes);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const handleClickAddNew = () => {
    setCleanState(!cleanState);
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="SignOnChain"
            routes={updatedRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        {/* {layout === "vr" && <Configurator />} */}
        <Routes>
          {getRoutes(updatedRoutes)}
          <Route exact path="/" element={<Navigate to="/add-file" />} />
          <Route exact path="/documents/details" component={DocumentDetails} />
          <Route exact path="/received-documents/details" component={ReceivedDocumentDetails} />

          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="SignOnChain"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            handleClickAddNew={handleClickAddNew}
          />
          {/* <Configurator />
          {configsButton} */}
        </>
      )}
      {/* {layout === "vr" && <Configurator />} */}
      <Context.Provider value={cleanState}>
        <Routes>
          {getRoutes(updatedRoutes)}
          <Route exact path="/" element={<Navigate to="/add-file" />} />
          <Route exact path="/documents/details" component={DocumentDetails} />
          <Route exact path="/received-documents/details" component={ReceivedDocumentDetails} />
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </Context.Provider>
    </ThemeProvider>
  );
}
