import { useState } from "react";
import "./pdf-style.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFPreview({ file, scale = 1.0, handleSetPages = () => {} }) {
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };

  const [numPages, setNumPages] = useState(null);
  const [pagesRendered, setPagesRendered] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    handleSetPages(numPages);
    setPagesRendered(1);
  };

  const onRenderSuccess = () => setPagesRendered((pagesRendered) => pagesRendered + 1);

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);
  return (
    <div>
      <div className="container">
        <div className="document">
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            className="reactPdf"
            loading=""
          >
            {Array.from(new Array(pagesRenderedPlusOne), (el, index) => {
              const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
              const isLastPage = numPages === index + 1;
              const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;

              return (
                <Page
                  key={`page_${index + 1}`}
                  onRenderSuccess={needsCallbackToRenderNextPage ? onRenderSuccess : null}
                  pageNumber={index + 1}
                  enderAnnotationLayer={false}
                  renderTextLayer={false}
                  scale={scale}
                />
              );
            })}
          </Document>
        </div>
      </div>
    </div>
  );
}

export default PDFPreview;
