/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

function Sessions({ profile }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleLogoutAll = async () => {
    try {
      await ApiService.logoutUserAllSessions(
        { userId: profile._id },
        navigate("/authentication/sign-in")
      );
    } catch (e) {
      console.error(e);

      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  return (
    <>
      <Card id="sessions">
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">{t("sessions")}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={3} sx={{ overflow: "auto" }}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={{ xs: "max-content", sm: "100%" }}
          >
            <MDBox display="flex" alignItems="center">
              <MDBox textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
                <Icon fontSize="default">desktop_windows</Icon>
              </MDBox>
              <MDBox height="100%" ml={2} lineHeight={1} mr={2}>
                <MDTypography display="block" variant="button" fontWeight="regular" color="text">
                  {profile.activeTokens ? (
                    `${profile.activeTokens} ${t("active-tokens")}`
                  ) : (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "50px" }} />
                  )}
                </MDTypography>
                <MDTypography variant="caption" color="text">
                  {t("your-sessions")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <MDButton variant="outlined" color="primary" onClick={handleLogoutAll}>
                {t("log-out-all")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("sessions")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default Sessions;
