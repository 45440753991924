/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import MDBox from "components/MDBox";

function ActionCell({ actions }) {
  return (
    <MDBox display="flex" alignItems="right">
      {actions}
    </MDBox>
  );
}

// Typechecking props for the StatusCell
ActionCell.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ActionCell;
