/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, createRef, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import "./style.css";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import { isObjectEmpty, formatDateTime } from "api/utils";
import Grid from "@mui/material/Grid";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Badge from "@mui/material/Badge";
import isEmail from "validator/lib/isEmail";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { getBase64Strings } from "exif-rotate-js/lib";
import { Context } from "context/Context";
import { isMobile } from "react-device-detect";

function UploadFile({
  profile,
  handleAction,
  fileRemoved,
  handleSetRemovefile,
  handleInputDisabled,
  handleRemoveFile,
}) {
  const cleanState = useContext(Context);
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedPicture, setUploadedPicture] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [fileDetails, setFileDetails] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [addFileMessage, setAddFileMessage] = useState("");
  const [receiversList, setReceiversList] = useState([]);
  const [fileTime, setFileTime] = useState(moment().add(7, "days"));
  const [timeDuration, setTimeDuration] = useState(3);
  const [timeValue, setTimeValue] = useState(t("years"));

  const [sign, setSign] = useState(false);
  const [send, setSend] = useState(false);
  const [price, setPrice] = useState({});
  const [inputDisabled, setInputDisabled] = useState(false);
  const [documentSigned, setDocumentSigned] = useState(false);
  const [allInvited, setAllInvited] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [numberOfSignatures, setNumberOfSignatures] = useState(0);
  const [numberOfReceivers, setNumberOfReceivers] = useState(0);

  const [signaturePrice, setSignaturePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [accessRightsPrice, setAccessRightsPrice] = useState(0);
  const [radioValue, setRadioValue] = useState(t("needs-to-sign"));

  const fileInput = createRef();

  const [hidePrice, setHidePrice] = useState(false);

  useEffect(() => {
    if (!isObjectEmpty(profile)) {
      const sdcSub = profile.subscriptions.filter((sub) => sub.type === "SDC")[0];
      if (!isObjectEmpty(sdcSub) && sdcSub.plan !== "PER OPERATION") {
        setHidePrice(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (fileRemoved) {
      setUploadedFile(null);
      setUploadedPicture("");
      setTotalPrice(0);
      setPrice({});
      setAccessRightsPrice(0);
      handleSetRemovefile(false);
      // setNumberOfSignatures(0);
      setSign(false);
      setSend(false);
      fileInput.current.value = null;
    }
  }, [fileRemoved]);

  useEffect(() => {
    setUploadedFile(null);
    setUploadedPicture("");
    setTotalPrice(0);
    setPrice({});
    setAccessRightsPrice(0);
    handleSetRemovefile(false);
    setNumberOfSignatures(0);
    setSign(false);
    setSend(false);
    setReceiversList([]);
    setMessage("");
    setTimeDuration(profile.trial ? 1 : 3);
    setTimeValue(profile.trial ? t("weeks") : t("years"));
    setAddFileMessage("");
    setInputDisabled(false);
    handleInputDisabled(false);
    setRadioValue(t("needs-to-sign"));
    setFileDetails({});
    setDocumentSigned(false);
    setAllInvited(false);
    handleRemoveFile();
    setNumberOfReceivers(0);

    fileInput.current.value = null;
    fileInput.current.value = null;
  }, [cleanState, t]);

  useEffect(() => {
    if (timeValue === t("days")) {
      setFileTime(moment().add(timeDuration, "days"));
    }
    if (timeValue === t("weeks")) {
      setFileTime(moment().add(timeDuration, "weeks"));
    }
    if (timeValue === t("months")) {
      setFileTime(moment().add(timeDuration, "months"));
    }
    if (timeValue === t("years")) {
      setFileTime(moment().add(timeDuration, "years"));
    }
  }, [timeDuration, timeValue]);

  const handleSetAddFileMessage = (e) => {
    if (e.target.value.length >= 500) {
      return;
    }
    setAddFileMessage(e.target.value);
  };

  const handleSaveReceiver = () => {
    if (emailAddress.length === 0) {
      setShow(true);
      setMessage(t("email-address-required"));
      setVariant("error");
      return;
    }
    const validEmail = isEmail(emailAddress);
    if (!validEmail) {
      setErrorMessage(t("invalid-email-address"));
      return;
    }
    setErrorMessage("");
    if (emailAddress === profile.email) {
      setErrorMessage("");
      return;
    }
    setReceiversList([
      ...receiversList,
      {
        email: emailAddress,
        type:
          radioValue === t("needs-to-sign")
            ? "SIGN"
            : radioValue === t("needs-to-sign-and-can-invite-others")
            ? "INVITE"
            : "READ",
      },
    ]);
    setEmailAddress("");
  };

  const handleSetEmailAddress = (e) => {
    if (e.target.value === profile.email) {
      setEmailAddress(e.target.value);
      setErrorMessage(t("cant-add-yourself-as-receiver"));
      return;
    }

    setErrorMessage("");
    setEmailAddress(e.target.value);

    // const validEmail = isEmail(emailAddress);
    // if (validEmail) {
    //   handleSaveReceiver();
    // }
  };

  const handleChangeRadio = (e, value) => {
    setRadioValue(value);
  };

  const handleChangeTimeValue = (e, value) => {
    setTimeValue(value);
  };

  const getFrontendUrlMagicLink = () => {
    const queryParameters = new URLSearchParams({
      data_id: fileDetails.data_id,
      sender: fileDetails.sender,
      receiver: fileDetails.receiver,
      hash: fileDetails.hash,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/email-sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const getFrontendUrl = () => {
    const queryParameters = new URLSearchParams({
      data_id: fileDetails.data_id,
      sender: fileDetails.sender,
      receiver: fileDetails.receiver,
      hash: fileDetails.hash,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const handleRemoveReceiver = (email, type) => {
    setReceiversList(receiversList.filter((rec) => !(rec.email === email && rec.type === type)));
  };

  const handleFileUpload = async () => {
    if (uploadedFile === null && uploadedPicture.length === 0) {
      setShow(true);
      setVariant("error");
      setMessage(t("please-upload-a-file"));
      return;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    const params = {};
    const fileTTL = Math.ceil((fileTime.endOf("day") - moment()) / 1000.0);

    try {
      const keyParams = {};
      const { address } = await ApiService.getPublicKey(keyParams, signal);
      params.receivers = JSON.stringify([address]);
    } catch (e) {
      console.error(e);
      setShow(true);
      setVariant("error");
      setMessage(e.message);
      return;
    }

    let responseData;

    if (uploadedPicture.length > 0) {
      const content = uploadedPicture.split(",");
      const mime = content[0].split(";")[0].split(":")[1];
      params.file = content[1];
      params.shareable = "true";
      params.ttl = fileTTL.toString();
      params.encoding = "base64";
      params.title = `Photo [${formatDateTime(moment())}]`;
      params.message = "Picture upload";
      params.flags = JSON.stringify([{ name: "signOnChain", value: true }]);
      params.disableNotifications = ApiService.disableNotifications;
      params.convertToPdf = "true";
      params.mimeType = mime;
      params.originalName = "webimage.png";

      // Upload picture to server
      try {
        responseData = await ApiService.uploadFileBase64(params, signal);
      } catch (e) {
        console.error(e);
        setShow(true);
        setVariant("error");
        setMessage(e.message);
        return;
      }
    } else {
      const fileTitle = uploadedFile.name;
      const selectedFile = uploadedFile;

      params.file = selectedFile;
      params.shareable = "true";
      params.ttl = fileTTL.toString();
      params.encoding = ApiService.storageFormat;
      params.title = fileTitle;
      params.message = "File upload";
      params.flags = JSON.stringify([{ name: "signOnChain", value: true }]);
      params.disableNotifications = ApiService.disableNotifications;

      if (selectedFile.type.startsWith("image")) {
        params.convertToPdf = "true";
      }

      // Upload file to server
      try {
        responseData = await ApiService.uploadFile(params, signal);
      } catch (e) {
        console.error(e);
        setShow(true);
        setVariant("error");
        setMessage(e.message);
        return;
      }
    }
    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId };

      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setVariant("error");
          setMessage(t("error-uploading-file"));
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          const data = responseData.details.blockchainTransactions[0];
          const queryParameters = new URLSearchParams({
            data_id: data.data_id,
            hash: data.rawHash,
            sender: data.senderAddress,
            receiver: data.receiverAddress,
          });

          const packageDetails = await ApiService.getPackageDetails(queryParameters);
          setFileDetails(packageDetails);
          setShow(true);
          setVariant("primary");
          setMessage(t("file-successfully-uploaded"));
        }
      }
      if (signal.aborted) {
        setShow(true);
        setVariant("error");
        setMessage(t("operation-aborted"));
        return;
      }
    } catch (e) {
      setShow(true);
      setVariant("error");
      setMessage(t("error-uploading-file"));
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSign = async () => {
    setNumberOfReceivers(0);
    setNumberOfSignatures(0);
    if (uploadedFile === null && uploadedPicture.length === 0) {
      setShow(true);
      setVariant("error");
      setMessage(t("please-upload-a-file"));
      return;
    }
    setSign(true);
    setInputDisabled(true);
    handleInputDisabled(true);
    await handleFileUpload();
  };

  const storeFile = async () => {
    if (uploadedFile === null && uploadedPicture.length === 0) {
      setShow(true);
      setVariant("error");
      setMessage(t("please-upload-a-file"));
      return;
    }
    setInputDisabled(true);
    handleInputDisabled(true);
    await handleFileUpload();
  };

  const handleSend = async () => {
    if (receiversList.length === 0 && emailAddress.length === 0) {
      setShow(true);
      setMessage(t("no-receivers-entered"));
      setVariant("error");
      return;
    }
    setInputDisabled(true);
    handleInputDisabled(true);

    setSend(true);
    await handleFileUpload();
  };

  const handleSignAndSend = async () => {
    if (receiversList.length === 0 && emailAddress.length === 0) {
      setShow(true);
      setMessage(t("no-receivers-entered"));
      setVariant("error");
      return;
    }
    setInputDisabled(true);
    handleInputDisabled(true);

    setSend(true);
    setSign(true);
    await handleFileUpload();
  };

  const handleDownloadReport = () => {
    const handlePreviewFile = async (userId, parameters, signal) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(reportParams, signal);

      const fileBuffer = await responseData.arrayBuffer();
      const uint8View = new Uint8Array(fileBuffer);
      handleAction({ data: uint8View }, "report");
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: fileDetails._id,
        parentType: "PACKAGE",
        frontendUrl: getFrontendUrl(),
        creatorName: "SignOnChain",
        documentTitle: "SignOnChain Report",
        reportTitle: "report",
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(reportParams, signal);
      } catch (e) {
        console.error("Error downloading package");
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
      }
    };
    handlePrepareFileDownload();
  };
  useEffect(() => {
    setNumberOfSignatures(
      receiversList.length === 0
        ? emailAddress.length > 0
          ? 1
          : 0
        : receiversList.filter((rec) => rec.type === "SIGN" || rec.type === "INVITE").length
    );
    setNumberOfReceivers(receiversList.filter((rec) => rec.type === "READ").length);
  }, [receiversList, emailAddress]);

  // sign file
  useEffect(() => {
    const signFile = async () => {
      setNumberOfSignatures(numberOfSignatures + 1);
      const params = {
        parentId: fileDetails._id,
        parentType: "PACKAGE",
        access: fileDetails.access,
        frontendUrl: getFrontendUrl(),
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      let responseData;
      try {
        responseData = await ApiService.addSignature(params, signal);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }

      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            setShow(true);
            setVariant("primary");
            setMessage(t("document-successfully-signed"));
            setDocumentSigned(true);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setShow(true);
        setVariant("warning");
        setMessage(e.message);
      }
    };
    sign && !isObjectEmpty(fileDetails) && signFile();
  }, [sign, fileDetails]);

  useEffect(() => {
    const sendFile = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      let returnData;
      const deploymentId = profile.endpoints.filter((ser) => ser.name === "SDC")[0].deploymentId;
      if (receiversList.length === 0 && emailAddress.length > 0) {
        const userExists = await ApiService.getUserExists({ deploymentId, email: emailAddress });
        if (!userExists.exists) {
          try {
            await ApiService.createGuestUser({
              name: emailAddress,
              email: emailAddress,
              enabledMagicLinkLogin: true,
              guestAccount: true,
              trialAccount: true,
              services: ApiService.defaultRegistrationServices,
              roles: ApiService.defaultRegistrationRoles,
              disableNotifications: "true",
              notifyLowCredits: true,
              lowCreditsThreshold: "0",
            });
          } catch (e) {
            console.error(e);
          }
        }
        try {
          returnData = await ApiService.addAccessRights(
            {
              parentId: fileDetails._id,
              parentType: "PACKAGE",
              type: "SIGN",
              email: emailAddress,
              endOfLife: "-1",
            },
            signal
          );
          setShow(true);
          setMessage(t("successfully-invited-receiver"));
          setVariant("primary");
        } catch (e) {
          console.error(e);
          setShow(true);
          setVariant("error");
          setMessage(e.message);
          return;
        }

        const params = {
          parentId: fileDetails._id,
          parentType: "PACKAGE",
          message: addFileMessage,
          accessRightsId: returnData.accessRights.filter((user) => user.email === emailAddress)[0]
            ._id,
          frontendUrl: getFrontendUrlMagicLink(),
          populateMagicLink: "true",
        };

        try {
          await ApiService.inviteAccessRights(params, signal);
        } catch (e) {
          console.error(e);
          setShow(true);
          setVariant("error");
          setMessage(e.message);
        }
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const elem of receiversList) {
          // check if user exists
          const userExists = await ApiService.getUserExists({ deploymentId, email: elem.email });
          if (!userExists.exists) {
            try {
              await ApiService.createGuestUser({
                name: elem.email,
                email: elem.email,
                enabledMagicLinkLogin: true,
                guestAccount: true,
                trialAccount: true,
                services: ApiService.defaultRegistrationServices,
                roles: ApiService.defaultRegistrationRoles,
                disableNotifications: "true",
                notifyLowCredits: true,
                lowCreditsThreshold: "0",
              });
            } catch (e) {
              console.error(e);
            }
          }
          try {
            returnData = await ApiService.addAccessRights(
              {
                parentId: fileDetails._id,
                parentType: "PACKAGE",
                type: elem.type,
                email: elem.email,
                endOfLife: "-1",
              },
              signal
            );
            setShow(true);
            setMessage(t("successfully-invited-receiver"));
            setVariant("primary");
          } catch (e) {
            console.error(e);
            setShow(true);
            setVariant("error");
            setMessage(e.message);
            return;
          }

          const params = {
            parentId: fileDetails._id,
            parentType: "PACKAGE",
            message: addFileMessage,
            accessRightsId: returnData.accessRights.filter((user) => user.email === elem.email)[0]
              ._id,
            frontendUrl: getFrontendUrlMagicLink(),
            populateMagicLink: "true",
          };

          try {
            await ApiService.inviteAccessRights(params, signal);
          } catch (e) {
            console.error(e);
            setShow(true);
            setVariant("error");
            setMessage(e.message);
          }
        }
      }

      setAllInvited(true);
    };
    send && !isObjectEmpty(fileDetails) && sendFile();
  }, [send, fileDetails]);

  useEffect(() => {
    // calculate price
    if (
      !isObjectEmpty(signaturePrice) &&
      !isObjectEmpty(accessRightsPrice) &&
      !isObjectEmpty(price)
    ) {
      let sigPrice = 0;
      if (sign && numberOfSignatures > 0) {
        sigPrice =
          numberOfSignatures * (signaturePrice.storagePrice + accessRightsPrice.storagePrice) -
          accessRightsPrice.storagePrice;
      } else {
        sigPrice =
          numberOfSignatures * (signaturePrice.storagePrice + accessRightsPrice.storagePrice);
      }

      const recPrice = numberOfReceivers * accessRightsPrice.storagePrice;
      const total = sigPrice + recPrice + price.storagePrice;
      setTotalPrice(total);
    }
  }, [
    numberOfSignatures,
    numberOfReceivers,
    price,
    signaturePrice,
    accessRightsPrice,
    uploadedFile,
    uploadedPicture,
  ]);

  const handleChangeFileTime = (e) => {
    if (profile.trial) {
      if (e.target.value > 30) {
        return;
      }
    }
    if (timeValue === "year(s)" && e.target.value >= 99) {
      setTimeDuration(99);
      return;
    }
    setTimeDuration(e.target.value);
  };

  useEffect(() => {
    if (sign && send && allInvited && documentSigned) {
      handleDownloadReport();
    }
    if (sign && !send && documentSigned) {
      handleDownloadReport();
    }
    if (send && !sign && allInvited) {
      handleDownloadReport();
    }
  }, [sign, send, allInvited, documentSigned]);

  useEffect(() => {
    const getPrice = async () => {
      const fileTTL = Math.ceil((fileTime.endOf("day") - moment()) / 1000.0);
      try {
        const resp = await ApiService.getPricingPerOperation({
          fileSizeBytes: uploadedFile.size,
          ttl: fileTTL.toString(),
          encoding: ApiService.storageFormat,
          storageService: "package",
          storageType: ApiService.packageStorageService.cassandra,
        });
        setPrice(resp);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }
      try {
        const respSig = await ApiService.getPricingPerOperation({
          fileSizeBytes: uploadedFile.size,
          ttl: fileTTL.toString(),
          encoding: ApiService.storageFormat,
          storageService: "signature",
          storageType: ApiService.packageStorageService.cassandra,
        });
        setSignaturePrice(respSig);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }
      try {
        const respAccessPrice = await ApiService.getPricingPerOperation({
          fileSizeBytes: uploadedFile.size,
          ttl: fileTTL.toString(),
          encoding: ApiService.storageFormat,
          storageService: "accessRights",
          storageType: ApiService.packageStorageService.cassandra,
        });
        setAccessRightsPrice(respAccessPrice);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }
    };

    !(uploadedFile === null) && !hidePrice && getPrice();
  }, [uploadedFile, fileTime, hidePrice]);

  useEffect(() => {
    const getPrice = async () => {
      const fileTTL = Math.ceil((fileTime.endOf("day") - moment()) / 1000.0);
      const base64Length = uploadedPicture.length - (uploadedPicture.indexOf(",") + 1);
      const padding =
        uploadedPicture.charAt(uploadedPicture.length - 2) === "="
          ? 2
          : uploadedPicture.charAt(uploadedPicture.length - 1) === "="
          ? 1
          : 0;
      const fileSize = base64Length * 0.75 - padding;

      try {
        const resp = await ApiService.getPricingPerOperation({
          fileSizeBytes: fileSize,
          ttl: fileTTL.toString(),
          encoding: ApiService.storageFormat,
          storageService: "package",
          storageType: ApiService.packageStorageService.cassandra,
        });
        setPrice(resp);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }
      try {
        const respSig = await ApiService.getPricingPerOperation({
          fileSizeBytes: fileSize,
          ttl: fileTTL.toString(),
          encoding: ApiService.storageFormat,
          storageService: "signature",
          storageType: ApiService.packageStorageService.cassandra,
        });
        setSignaturePrice(respSig);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }
      try {
        const respAccessPrice = await ApiService.getPricingPerOperation({
          fileSizeBytes: fileSize,
          ttl: fileTTL.toString(),
          encoding: ApiService.storageFormat,
          storageService: "accessRights",
          storageType: ApiService.packageStorageService.cassandra,
        });
        setAccessRightsPrice(respAccessPrice);
      } catch (e) {
        setShow(true);
        setVariant("error");
        setMessage(e.message);
      }
    };
    uploadedPicture.length > 0 && !hidePrice && getPrice();
  }, [uploadedPicture, fileTime, hidePrice]);

  const handleNewUpload = () => {
    setAddFileMessage("");
    setInputDisabled(false);
    handleInputDisabled(false);

    setReceiversList([]);
    setRadioValue(t("needs-to-sign"));
    setFileDetails({});
    setUploadedFile(null);
    setDocumentSigned(false);
    setAllInvited(false);
    handleRemoveFile();

    setEmailAddress("");
    setTimeDuration(profile.trial ? 1 : 3);
    setTimeValue(profile.trial ? t("weeks") : t("years"));
    setPrice({});
    setTotalPrice(0);
    setNumberOfReceivers(0);
    setNumberOfSignatures(0);
    setSign(false);
    setSend(false);
    fileInput.current.value = null;
  };

  const [openCamera, setOpenCamera] = useState(false);
  const handleOpenCamera = () => {
    setOpenCamera(true);
  };
  const handleCloseCamera = () => {
    setOpenCamera(false);
  };

  const handleTakePhoto = (dataUri) => {
    fileInput.current.value = null;
    setUploadedFile(null);

    setTotalPrice(0);
    setPrice({});
    setAccessRightsPrice(0);

    handleCloseCamera();
    setUploadedPicture(dataUri);
    handleAction(dataUri, "picture");
    // Do stuff with the photo...
  };

  const [openPricingModal, setOpenPricingModal] = useState(false);

  const handleOpenPricingModal = () => {
    setOpenPricingModal(true);
  };

  const handleClosePricingModal = () => {
    setOpenPricingModal(false);
  };

  const formatSize = (fileSize) => {
    const units = ["B", "KB", "MB", "GB", "TB"];

    let l = 0;
    let n = parseInt(fileSize, 10) || 0;

    // eslint-disable-next-line no-plusplus
    while (n >= 1000 && ++l) {
      // eslint-disable-next-line operator-assignment
      n = n / 1000;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 2)} ${units[l]}`;
  };

  return (
    <>
      <Card>
        <MDBox
          position="relative"
          borderRadius="lg"
          mt={-3}
          mx={2}
          className="card-header"
          sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
        >
          <>
            <MDBox
              borderRadius="lg"
              sx={
                inputDisabled || !profile.validatedDocuments || !profile.validatedEmail
                  ? { background: "#344767", opacity: "0.5" }
                  : { background: "#017f98" }
              }
              shadow="sm"
              width="100%"
              height="100%"
              position="relative"
              zIndex={10}
              mb={0}
            >
              <div style={{ textAlign: "center" }}>
                <div className="picture1" style={{ marginBottom: "0px" }}>
                  <label htmlFor="files" style={{ display: "block", color: "#fff" }}>
                    <MDTypography variant="h6" color="light">
                      {uploadedFile?.name ? uploadedFile?.name : t("upload-or-drop-file")}
                    </MDTypography>
                    <MDTypography variant="caption" color="light">
                      {uploadedFile?.name ? "" : t("only-pdf-and-image-files-are-supported")}
                    </MDTypography>
                  </label>
                  <input
                    style={{
                      cursor: "pointer",
                      display: "block",
                      height: "100%",
                      left: "0",
                      opacity: "0",
                      position: "absolute",
                      top: "0",
                      width: "100%",
                    }}
                    type="file"
                    disabled={
                      inputDisabled || !profile.validatedDocuments || !profile.validatedEmail
                    }
                    id="files"
                    accept="application/pdf, image/*"
                    ref={fileInput}
                    onChange={async (e) => {
                      const selectedFile = e.target.files[0];
                      if (selectedFile.type.startsWith("image")) {
                        const data = await getBase64Strings(e.target.files);
                        if (data.length > 0) {
                          setUploadedPicture(data[0]);
                          return handleAction(data[0], "picture");
                        }
                      }
                      if (selectedFile) {
                        setUploadedFile(selectedFile);
                        handleAction(selectedFile);
                      }
                    }}
                  />
                </div>
              </div>
            </MDBox>
            <MDTypography variant="caption">{}</MDTypography>
          </>
        </MDBox>
        {!isMobile && (
          <MDBox
            style={{ textAlign: "center", marginBottom: "-16px" }}
            sx={{ display: { xs: "block" } }}
          >
            <Tooltip title={t("take-a-photo")}>
              <MDButton
                variant="text"
                color="primary"
                onClick={handleOpenCamera}
                disabled={inputDisabled}
              >
                <Icon>camera_alt</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
        )}

        {/* <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={-10}
            position="relative"
            zIndex={1}
          >
            <MDBox mr={1}>
              <MDButton variant="gradient" color="primary" size="small">
                edit
              </MDButton>
            </MDBox>
            <MDButton variant="outlined" color="dark" size="small">
              remove
            </MDButton>
          </MDBox> */}

        <MDBox p={2}>
          {!inputDisabled && (
            <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
              <Grid item xs={12}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {t("add-receiver")}
                </MDTypography>
              </Grid>

              {receiversList.map((receiver) => (
                <Grid item xs={12}>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={1}
                  >
                    <MDBox>
                      <MDTypography display="block" variant="button" fontWeight="medium">
                        {receiver.email}
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular" color="text">
                        {receiver.type}
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center">
                      {!inputDisabled && (
                        <MDBox
                          display="flex"
                          alignItems="center"
                          lineHeight={1}
                          ml={3}
                          sx={{ cursor: "pointer" }}
                        >
                          <MDButton
                            onClick={() => handleRemoveReceiver(receiver.email, receiver.type)}
                          >
                            <Icon fontSize="small">remove</Icon>
                          </MDButton>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              ))}
              {errorMessage.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="caption" style={{ color: "#7e2d00" }}>
                    {errorMessage}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={7} lg={5} xl={5} style={{ paddingTop: "0px" }}>
                <MDInput
                  style={{ paddingBottom: "3px" }}
                  variant="standard"
                  value={emailAddress}
                  error={errorMessage.length > 0}
                  onChange={handleSetEmailAddress}
                  fullWidth
                  label={t("email-address")}
                  inputProps={{ type: "email", autoComplete: "" }}
                />
              </Grid>

              <Grid item xs={12} sm={3} lg={4} xl={4}>
                <Autocomplete
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      paddingRight: "0px!important",
                    },
                  }}
                  defaultValue={t("needs-to-sign")}
                  value={radioValue}
                  disableClearable
                  onChange={handleChangeRadio}
                  options={[
                    t("needs-to-sign"),
                    t("needs-to-sign-and-can-invite-others"),
                    t("send-a-copy"),
                  ]}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                />
              </Grid>
              <Grid item xs={12} sm={2} lg={3} xl={3}>
                <MDBox pt={1}>
                  <MDButton
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleSaveReceiver}
                  >
                    <Icon>add</Icon>
                    {t("add")}
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          )}
          {!inputDisabled ? (
            <>
              <Divider />
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="h6">{t("add-message")}</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox display="flex" alignItems="flex-end">
                    {showMessage ? (
                      <MDButton
                        variant="text"
                        color="info"
                        iconOnly
                        onClick={() => setShowMessage(false)}
                      >
                        <Icon>expand_less</Icon>
                      </MDButton>
                    ) : (
                      <MDButton
                        variant="text"
                        color="info"
                        iconOnly
                        onClick={() => setShowMessage(true)}
                      >
                        <Icon>expand_more</Icon>
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {addFileMessage.length > 0 && (
                <>
                  <Divider />
                  <MDBox
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDBox display="flex" alignItems="center">
                      <MDTypography variant="h6">{t("message")}</MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDInput
                    value={addFileMessage}
                    onChange={handleSetAddFileMessage}
                    fullWidth
                    multiline
                    disabled
                    rows={3}
                    inputProps={{ type: "text", autoComplete: "" }}
                  />
                </>
              )}
            </>
          )}

          {!inputDisabled && (
            <MDBox
              pt={1}
              sx={
                showMessage
                  ? { transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }
                  : { display: "none" }
              }
            >
              <MDInput
                value={addFileMessage}
                onChange={handleSetAddFileMessage}
                fullWidth
                label={t("message-max-500")}
                multiline
                rows={3}
                inputProps={{ type: "text", autoComplete: "" }}
              />
            </MDBox>
          )}

          <Divider />
          {/*          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="h6">{t("storage-period")}</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <MDBox display="flex" alignItems="flex-end">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={fileTime}
                    onChange={(newValue) => handleChangeFileTime(newValue)}
                  />
                </LocalizationProvider>
              </MDBox>
            </MDBox>
          </MDBox> */}
          <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("storage-period")}
              </MDTypography>
              {profile.trial && (
                <MDTypography variant="caption">
                  {t("in-trial-mode-storage-period-30-days")}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={12} sm={7} lg={7} xl={7} style={{ paddingTop: "0px" }}>
              <MDInput
                type="number"
                style={{ paddingBottom: "3px" }}
                variant="standard"
                value={timeDuration}
                onChange={handleChangeFileTime}
                fullWidth
                label={t("")}
                inputProps={{ min: 1, max: profile.trial ? 30 : "" }}
              />
            </Grid>

            <Grid item xs={12} sm={5} lg={5} xl={5}>
              <Autocomplete
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    paddingRight: "0px!important",
                  },
                }}
                defaultValue={profile.trial ? "days" : "year"}
                disableClearable
                value={timeValue}
                onChange={handleChangeTimeValue}
                options={
                  profile.trial ? [t("days")] : [t("days"), t("weeks"), t("months"), t("years")]
                }
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
          <Divider />
          {!hidePrice && (
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox display="flex" alignItems="center">
                <Tooltip placement="right-start" title={t("show-pricing-info")}>
                  <Button sx={{ paddingLeft: "0px" }} onClick={handleOpenPricingModal}>
                    <Badge
                      badgeContent={t("i")}
                      color="primary"
                      sx={[
                        {
                          "& .MuiBadge-badge": {
                            width: "15px",
                            minWidth: "15px",
                            height: "15px",
                            fontSize: "0.5rem",
                            backgroundColor: "#017f98",
                          },
                        },
                      ]}
                    >
                      <MDTypography variant="h6">{t("price")}</MDTypography>
                    </Badge>
                  </Button>
                </Tooltip>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                <MDBox display="flex" alignItems="flex-end">
                  <MDTypography variant="h6">
                    {totalPrice &&
                      ApiService.formatPricing(totalPrice, price.currency, t("less-than-zero"))}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          )}

          {!isObjectEmpty(price) && (
            <>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption">{t("file-size")}</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox display="flex" alignItems="flex-end">
                    <MDTypography variant="caption">{formatSize(price.fileSizeBytes)}</MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption">{t("signatures")}</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox display="flex" alignItems="flex-end">
                    <MDTypography variant="caption">{numberOfSignatures}</MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption">{t("receivers")}</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox display="flex" alignItems="flex-end">
                    <MDTypography variant="caption">{numberOfReceivers}</MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </>
          )}

          {inputDisabled ? (
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={5}>
              <MDButton size="small" color="primary" variant="contained" onClick={handleNewUpload}>
                {t("add-new-file")}
              </MDButton>
            </MDBox>
          ) : (
            <div
              div
              style={{
                display: "inline-block",
                marginLeft: "auto",
                marginRight: "auto",
                float: "right",
              }}
            >
              <MDBox mt={5}>
                <Tooltip title={t("cancel")}>
                  <IconButton size="small" color="primary" variant="text" onClick={handleNewUpload}>
                    <Icon>cancel</Icon>
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("store-and-secure-document")} placement="top">
                  <MDButton
                    color="primary"
                    size="small"
                    variant="text"
                    onClick={storeFile}
                    disabled={!profile.validatedDocuments || !profile.validatedEmail}
                  >
                    {t("store")}
                  </MDButton>
                </Tooltip>
                <Tooltip
                  title={
                    totalPrice && !isObjectEmpty(signaturePrice)
                      ? `${t("sign-the-document-yourself")} (${signaturePrice.storagePrice} EUR) `
                      : t("sign-the-document-yourself")
                  }
                  placement="top"
                >
                  <MDButton
                    size="small"
                    color="primary"
                    variant="text"
                    style={{ marginRight: "5px" }}
                    onClick={handleSign}
                    disabled={!profile.validatedDocuments || !profile.validatedEmail}
                  >
                    {t("sign")}
                  </MDButton>
                </Tooltip>
                <Tooltip title={t("send-document-to-the-receivers")} placement="top">
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "5px" }}
                    onClick={handleSend}
                    disabled={!profile.validatedDocuments || !profile.validatedEmail}
                  >
                    {t("send")}
                  </MDButton>
                </Tooltip>
                <Tooltip
                  title={
                    totalPrice && !isObjectEmpty(signaturePrice)
                      ? `${t("sign-the-document")} (${signaturePrice.storagePrice} EUR) ${t(
                          "and-send-it-to-the-receivers"
                        )}, ${t("price")}: ${ApiService.formatPricing(
                          totalPrice + signaturePrice.storagePrice,
                          price.currency,
                          t("less-than-zero")
                        )}`
                      : t("sign-the-document-and-send-it-to-the-receivers")
                  }
                  placement="top"
                >
                  <MDButton
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleSignAndSend}
                    disabled={!profile.validatedDocuments || !profile.validatedEmail}
                  >
                    {t("sign-and-send")}
                  </MDButton>
                </Tooltip>
              </MDBox>
            </div>
          )}
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("add-file")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />

      <Dialog open={openCamera} onClose={handleCloseCamera} fullWidth maxWidth="lg">
        <DialogTitle>{t("take-a-photo")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Camera
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseFoodModal}>{t("cancel")}</Button>
          <Button onClick={handleUpdateSign}>{t("sign")}</Button>
        </DialogActions> */}
      </Dialog>

      <Dialog open={openPricingModal} onClose={handleClosePricingModal}>
        <DialogTitle>{t("pricing-information")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>
              {t("price-is-based-on-document-size-storage-time-and-number-of-signatures")}
            </span>
            <br />
            <br />
            <span>{`${t("document")}: `}</span>
            <span style={{ fontWeight: "600" }}>{`€${ApiService.minDocumentPrice}`}</span>
            <span>{` + ${t("size-storage-time")}.`}</span>
            <br />
            <span>{`${t("signature")}: `}</span>
            <span style={{ fontWeight: "600" }}>{`€${ApiService.minSignaturePrice}`}</span>
            <span>.</span>
            <br />
            <span>{`${t("reader")}: `}</span>
            <span style={{ fontWeight: "600" }}>{`€${ApiService.minReadingPrice}`}</span>
            <span>.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClosePricingModal}>{t("close")}</MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadFile;
