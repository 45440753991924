/* eslint-disable react/no-unstable-nested-components */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ApiService from "api/ApiService";
import { useLocation } from "react-router-dom";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import { formatDate, isObjectEmpty } from "api/utils";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import MDSnackbar from "components/MDSnackbar";
import moment from "moment";
import Link from "@mui/material/Link";

function DocumentsPage() {
  const location = useLocation();
  const { t } = useTranslation();

  const [menu, setMenu] = useState(null);
  const [myFiles, setMyFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("");
  const [documentType, setDocumentType] = useState("Documents");
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [myKeyAddress, setMyKeyAddress] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [lookupUsers, setLookupUsers] = useState({});

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const handleSetFilter = (docType) => {
    setDocumentType(docType);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const getUsers = async () => {
      const responseData = await ApiService.getAllUsers({});
      const users = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of responseData) {
        users[entry.address] = entry.user.email;
      }
      setLookupUsers(users);
    };
    getUsers();
  }, []);

  const getQueryParams = useCallback(
    async (signal = undefined) => {
      const queryParams = new URLSearchParams(location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;

      return params;
    },
    [location.search]
  );

  const handleRefresh = useCallback(
    async (signal = undefined, userProfile = {}) => {
      const getSendData = async (signal = undefined, queryParams) => {
        try {
          setLoadingFiles(true);
          const myAddress = await ApiService.getPublicKey(
            {
              userId: queryParams.userId,
              serviceType: "SDC",
              keyType: "MASTER",
            },
            signal
          );
          setMyKeyAddress(myAddress);
          const params = {
            userId: queryParams.userId,
            sortBy: "createdAt:desc",
            populateSignatures: true,
            populateAccessRights: true,
          };

          if (documentType === "My Documents") {
            params.receiver = myAddress.address;
            params.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [
                      { "flags.name": "signOnChain" },
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
                : {
                    $and: [
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
            );
          } else if (documentType === "Received Documents") {
            params.parentType = ApiService.parentTypes.package;
            params.populateParent = true;
            params.populateSignatures = true;
            params.populateAccessRights = true;
            if (!ApiService.production) {
              params.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                  { $or: [{ "flags.name": "signOnChain" }, { "flags.name": "FOOD_FORM" }] },
                ],
              });
            } else {
              params.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },

                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              });
            }
          } else if (documentType === "Bin") {
            params.receiver = myAddress.address;
            params.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [{ "flags.name": "signOnChain" }, { "flags.name": "bin" }],
                  }
                : { "flags.name": "bin" }
            );
          }

          if (queryParams.parentId) {
            params.parentId = queryParams.parentId;
          }

          let returnData;
          if (documentType === "Received Documents") {
            returnData = await ApiService.getAccessRights(params, signal);
            setMyFiles(returnData.filter((file) => file.parentId === undefined));
          } else if (documentType === "My Documents") {
            returnData = await ApiService.getSendData(params, signal);
            setMyFiles(returnData.filter((file) => file.parentId === undefined));
          } else if (documentType === "Bin") {
            const myBinData = await ApiService.getSendData(params, signal);
            setMyFiles(myBinData.filter((file) => file.parentId === undefined));

            const recParams = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              populateSignatures: true,
              populateAccessRights: true,
              parentType: ApiService.parentTypes.package,
              populateParent: true,
            };
            recParams.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [
                      { "flags.name": "signOnChain" },
                      { "flags.name": `${userProfile._id}-remove` },
                    ],
                  }
                : { "flags.name": `${userProfile._id}-remove` }
            );
            const receivedBinData = await ApiService.getAccessRights(recParams, signal);
            const documents = myBinData.concat(receivedBinData);

            setMyFiles(documents.filter((file) => file.parentId === undefined));
          }

          if (documentType === "Documents") {
            // get all received and my except Bin
            const myDoc = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              populateSignatures: true,
              populateAccessRights: true,
            };
            myDoc.receiver = myAddress.address;
            myDoc.filters = JSON.stringify(
              !ApiService.production
                ? {
                    $and: [
                      { "flags.name": "signOnChain" },
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
                : {
                    $and: [
                      { "flags.name": { $ne: "bin" } },
                      { status: { $ne: "EXPIRED" } },
                      { status: { $ne: "DELETED" } },
                    ],
                  }
            );

            const recDoc = {
              userId: queryParams.userId,
              sortBy: "createdAt:desc",
              populateSignatures: true,
              populateAccessRights: true,
            };
            recDoc.parentType = ApiService.parentTypes.package;
            recDoc.populateParent = "true";

            if (!ApiService.production) {
              recDoc.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                  { $or: [{ "flags.name": "signOnChain" }, { "flags.name": "FOOD_FORM" }] },
                ],
              });
            } else {
              recDoc.filters = JSON.stringify({
                $and: [
                  { "flags.name": { $ne: "bin" } },
                  { "flags.name": { $ne: `${userProfile._id}-remove` } },
                  { status: { $ne: "EXPIRED" } },
                  { status: { $ne: "DELETED" } },
                ],
              });
            }

            const myDocuments = await ApiService.getSendData(myDoc, signal);
            const received = await ApiService.getAccessRights(recDoc, signal);

            const documents = received.concat(myDocuments);

            setMyFiles(documents.filter((file) => file.parentId === undefined));
          }
        } catch (e) {
          console.error(e);
          setMessage(e.message);
          setVariant("error");
          setShow(true);
        } finally {
          setLoadingFiles(false);
        }
      };
      const queryParams = await getQueryParams(signal);
      await getSendData(signal, queryParams);
    },
    [getQueryParams, documentType]
  );

  const handleRestoreFromBin = async (dataId, sender, receiver, hash) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const params = {
      data_id: dataId,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([{ name: "signOnChain", value: true }]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      await handleRefresh();
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const [fileDeleted, setFileDeleted] = useState(false);

  const handleUpdateFlag = async (dataId, sender, receiver, hash) => {
    const { signal } = new AbortController();
    const params = {
      data_id: dataId,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([
        { name: "signOnChain", value: true },
        { name: `${userProfile._id}-remove`, value: true },
      ]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      setMessage(t("successfully-moved-to-bin"));
      setVariant("info");
      setShow(true);
      setMyFiles(myFiles.filter((file) => file.data_id !== dataId));
      setFileDeleted(true);
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const handleMoveToBin = async (dataId, sender, receiver, hash) => {
    const { signal } = new AbortController();
    const params = {
      data_id: dataId,
      sender,
      receiver,
      hash,
      flags: JSON.stringify([
        { name: "signOnChain", value: true },
        { name: "bin", value: true },
      ]),
    };
    try {
      await ApiService.updateFlags(params, signal);
      setMessage(t("successfully-moved-to-bin"));
      setVariant("info");
      setShow(true);
      setMyFiles(myFiles.filter((file) => file.data_id !== dataId));
      setFileDeleted(true);
    } catch (e) {
      console.error(e);
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const prepareDataForTable = (files) => {
    const checkConfirmed = (signatures, accessRights) => {
      if (accessRights.length === 0 && signatures.length === 0) {
        return {
          status: "stored",
          mobile: t("stored"),
          description: t("this-document-is-stored"),
        };
      }
      if (accessRights.length === 0 && signatures.length > 0) {
        return {
          status: "signed-owner",
          mobile: t("signed-by-owner"),
          description: t("this-document-is-signed-by-the-owner"),
        };
      }
      const signSet = new Set();
      signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

      const accessSet = new Set();
      accessRights
        .filter((access) => access.type === "SIGN")
        .map((acc) => accessSet.add(acc.email));

      if (signSet.size === 0 && accessSet.size === 0) {
        return {
          status: "stored",
          mobile: t("stored"),
          description: t("this-document-is-stored"),
        };
      }
      if (accessSet.size === 0 && signSet.size > 0) {
        return {
          status: "signed-owner",
          mobile: t("signed-by-owner"),
          description: t("this-document-is-signed-by-the-owner"),
        };
      }
      if (
        (signSet.size === accessSet.size &&
          !accessSet.has(userProfile.email) &&
          !signSet.has(userProfile.email) &&
          accessRights.filter((acc) => acc.email === userProfile.email).length === 0) || // just send
        signSet.size === accessSet.size + 1
      ) {
        return {
          status: "signed",
          mobile: t("signed"),
          description: t("this-document-is-signed-and-signatures-are-written-to-blockchain"),
        };
      }
      return {
        status: "not-signed",
        mobile: t("not-signed"),
        description: t("this-document-is-not-yet-fully-signed"),
      };
      // eslint-disable-next-line no-else-return
    };

    return files.map((file) => {
      const queryParameters = new URLSearchParams({
        data_id: file.data_id,
        sender: file.sender,
        receiver: file.receiver,
        hash: file.hash,
      }).toString();
      return {
        title: JSON.stringify({ title: file.title, params: queryParameters }),
        endOfLife:
          (file.ttl === -1 || file.ttl === undefined) && file.endOfLife === undefined
            ? t("file-stored-forever")
            : formatDate(file.endOfLife),
        createdAt: formatDate(file.createdAt),
        docType: file?.metadata?.find((data) => data.name === t("document-type"))?.metadata,
        confirmedStatus:
          !isObjectEmpty(file) && checkConfirmed(file?.signatures, file?.accessRights),
        actions: (
          <>
            {(documentType === "My Documents" ||
              documentType === "Received Documents" ||
              documentType === "Documents") && (
              <MDBox mr={1}>
                <Tooltip title={t("view-details")} placement="top">
                  <MDButton
                    color="primary"
                    size="small"
                    iconOnly
                    circular
                    href={`/documents/details?${queryParameters}`}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>more_vert</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "Documents" && file.sender === myKeyAddress.address && (
              <MDBox mr={1}>
                <Tooltip title={t("move-to-bin")} placement="top">
                  <MDButton
                    color="warning"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleMoveToBin(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "Documents" && file.sender !== myKeyAddress.address && (
              <MDBox mr={1}>
                <Tooltip title={t("remove-received-document")} placement="top">
                  <MDButton
                    color="light"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleUpdateFlag(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>remove</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "My Documents" && (
              <MDBox mr={1}>
                <Tooltip title={t("move-to-bin")} placement="top">
                  <MDButton
                    color="warning"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleMoveToBin(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "Received Documents" && (
              <MDBox mr={1}>
                <Tooltip title={t("remove-received-document")} placement="top">
                  <MDButton
                    color="light"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleUpdateFlag(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>remove</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
            {documentType === "Bin" && (
              <MDBox mr={1}>
                <Tooltip title={t("restore-from-bin")} placement="top">
                  <MDButton
                    color="warning"
                    size="small"
                    iconOnly
                    circular
                    onClick={() =>
                      handleRestoreFromBin(file.data_id, file.sender, file.receiver, file.hash)
                    }
                  >
                    <Icon sx={{ fontWeight: "bold" }}>restore</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            )}
          </>
        ),
      };
    });
  };

  const structureDataForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("title"),
          accessor: "title",
          Cell: ({ value }) => {
            const jsonValue = JSON.parse(value);
            return (
              <Link href={`/documents/details?${jsonValue.params}`} underline="none">
                <DefaultCell value={jsonValue.title} />
              </Link>
            );
          },
        },
        {
          Header: t("EOL"),
          accessor: "endOfLife",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("created-at"),
          accessor: "createdAt",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("confirmed-status"),
          accessor: "confirmedStatus",
          Cell: ({ value }) => {
            let status;
            if (value.status === "stored") {
              status = <StatusCell icon="save" color="success" status={t("stored")} />;
            } else if (value.status === "signed-owner") {
              status = <StatusCell icon="done" color="primary" status={t("signed-by-owner")} />;
            } else if (value.status === "signed") {
              status = <StatusCell icon="done" color="primary" status={t("signed")} />;
            } else {
              status = <StatusCell icon="replay" color="error" status={t("not-yet-signed")} />;
            }
            return status;
          },
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(files),
    };
    return dataTableData;
  };

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
        resp.validatedDocuments && resp.validatedEmail && (await handleRefresh(undefined, resp));
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, [handleRefresh]);

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        await ApiService.loginRequired(signal, false);
      } catch (e) {
        console.error(e);
        setMessage(e.message);
        setVariant("error");
        setShow(true);
      }
    };
    apiOperations();
  }, []);

  // eslint-disable-next-line consistent-return
  const getTitle = (title) => {
    if (title === t("documents")) {
      return t("documents");
    }
    if (title === t("my-documents")) {
      return t("my-documents");
    }
    if (title === t("received-documents")) {
      return t("received-documents");
    }
    if (title === t("bin")) {
      return t("bin");
    }
  };
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          handleSetFilter("Documents");
          return closeMenu();
        }}
      >
        {t("documents")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("My Documents");
          return closeMenu();
        }}
      >
        {t("my-documents")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("Received Documents");
          return closeMenu();
        }}
      >
        {t("received-documents")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSetFilter("Bin");
          return closeMenu();
        }}
      >
        {t("bin")}
      </MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem
        onClick={() => {
          handleSetFilter("Documents");
          return closeMenu();
        }}
      >
        <MDTypography variant="button" color="error" fontWeight="regular">
          {t("remove-filter")}
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="primary">
            new order
          </MDButton> */}
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              {t("filter-documents")}
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            {/* <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {getTitle(documentType)}
            </MDTypography>
          </MDBox>
          <DataTable
            loading={loadingFiles}
            table={structureDataForTable(
              myFiles.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
            )}
            entriesPerPage={false}
            canSearch
            fileDeleted={fileDeleted}
          />
        </Card>
      </MDBox>
      <Footer />
      <MDSnackbar
        icon="notifications"
        title={t("documents")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </DashboardLayout>
  );
}

export default DocumentsPage;
