/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import { isObjectEmpty } from "api/utils";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";

function BasicInfo({ organizationProfile }) {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(true);

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const [parentOrganization, setParentOrganization] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [name, setName] = useState("");
  const [usersLimit, setUsersLimit] = useState("");
  const [email, setEmail] = useState("");
  const [paymailHandle, setPaymailHandle] = useState("");
  const [subdomains, setSubdomains] = useState("");
  const [number, setNumber] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [vatRate, setVatRate] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [country, setCountry] = useState("");
  const [landingPageUri, setLandingPageUri] = useState("");

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!isObjectEmpty(organizationProfile)) {
      setParentOrganization(organizationProfile.parentOrg ? organizationProfile.parentOrg : "");
      setChecked(organizationProfile.chargeOrganization ? [1] : []);
      setOrganizationType(organizationProfile.type);
      setName(organizationProfile.name);
      setNumber(organizationProfile.number ? organizationProfile.number : "");
      setAddress(organizationProfile.address ? organizationProfile.address : "");
      setCity(organizationProfile.city ? organizationProfile.city : "");
      setCountry(organizationProfile.country ? organizationProfile.country : "");
      setVatRate(organizationProfile.vatRate ? organizationProfile.vatRate : "");
      setPostalCode(organizationProfile.postalCode ? organizationProfile.postalCode : "");
      setVatNumber(organizationProfile.vatNumber ? organizationProfile.vatNumber : "");
      setContactPerson(organizationProfile.contactPerson ? organizationProfile.contactPerson : "");
      setPhoneNumber(organizationProfile.phoneNumber ? organizationProfile.phoneNumber : "");
      setSubdomains(organizationProfile.subdomains ? organizationProfile.subdomains : "");
      setLandingPageUri(
        organizationProfile.landingPageUri ? organizationProfile.landingPageUri : ""
      );
      setEmail(organizationProfile.email ? organizationProfile.email : "");
      setPaymailHandle(
        organizationProfile?.paymailHandles?.length > 0
          ? organizationProfile.paymailHandles[0].handle
          : ""
      );
      setUsersLimit(organizationProfile?.usersLimit?.toString());
    }
  }, [organizationProfile]);

  const handleUpdateProfileSubmit = async (event) => {
    event.preventDefault();
    const requestData = {
      organizationId: organizationProfile._id,
      chargeOrganization: checked.length > 0,
    };

    if (parentOrganization.length > 0 && parentOrganization !== organizationProfile.parentOrg) {
      requestData.parentOrg = parentOrganization;
    }
    if (organizationType.length > 0 && organizationType !== organizationProfile.type) {
      requestData.type = organizationType;
    }
    if (name.length > 0 && name !== organizationProfile.name) {
      requestData.name = name;
    }
    if (number.length > 0 && number !== organizationProfile.number) {
      requestData.number = number;
    }
    if (address.length > 0 && address !== organizationProfile.address) {
      requestData.address = address;
    }
    if (city.length > 0 && city !== organizationProfile.city) {
      requestData.city = city;
    }
    if (country.length > 0 && country !== organizationProfile.country) {
      requestData.country = country;
    }
    if (postalCode.length > 0 && postalCode !== organizationProfile.postalCode) {
      requestData.postalCode = postalCode;
    }
    if (vatNumber.length > 0 && vatNumber !== organizationProfile.vatNumber) {
      requestData.vatNumber = vatNumber;
    }
    if (contactPerson.length > 0 && contactPerson !== organizationProfile.contactPerson) {
      requestData.contactPerson = contactPerson;
    }
    if (phoneNumber.length > 0 && phoneNumber !== organizationProfile.phoneNumber) {
      requestData.phoneNumber = phoneNumber;
    }
    if (subdomains.length > 0 && subdomains !== organizationProfile.subdomains) {
      requestData.subdomains = subdomains;
    }
    if (landingPageUri.length > 0 && landingPageUri !== organizationProfile.landingPageUri) {
      requestData.landingPageUri = landingPageUri;
    }
    if (email.length > 0 && email !== organizationProfile.email) {
      const validEmail = isEmail(email);
      if (validEmail) {
        requestData.email = email;
        // } else {
        //   setEmailError(t("invalid-email-address"));
        //   messageContent.push(t("email-address"));
        //   checksPassed = false;
      }
    }
    const currentPaymailHandle =
      organizationProfile.paymailHandles.length > 0
        ? organizationProfile.paymailHandles[0].handle
        : "";
    if (paymailHandle.length > 0 && paymailHandle !== currentPaymailHandle) {
      requestData.paymailHandle = paymailHandle === " " ? "" : paymailHandle;
    }
    if (usersLimit.length > 0 && usersLimit !== organizationProfile.usersLimit.toString()) {
      const validUsersLimit = isNumeric(usersLimit);
      if (validUsersLimit) {
        const usersLimitParsed = parseInt(usersLimit, 10);
        if (usersLimitParsed >= 0) {
          requestData.usersLimit = usersLimitParsed;
        }
      }
      //   } else {
      //     validUsersLimit = false;
      //   }
      // }
      // if (!validUsersLimit) {
      //   setUsersLimitError(t("invalid-users-limit"));
      //   messageContent.push(t("users-limit"));
      //   checksPassed = false;
      // }
    }

    if (vatRate.length > 0 && vatRate !== organizationProfile.vatRate) {
      const validVatRate = isNumeric(vatRate);
      if (validVatRate) {
        const vatRateParsed = parseInt(vatRate, 10);
        if (vatRateParsed >= 0) {
          requestData.vatRate = vatRate;
        }
      }
      //   } else {
      //     validVatRate = false;
      //   }
      // }
      // if (!validVatRate) {
      //   setVatRateError(t("invalid-vat-rate"));
      //   messageContent.push(t("vat-rate"));
      //   checksPassed = false;
      // }
    }
    // if (!checksPassed) {
    //   showErrorMessage(`${t("invalid-information-provided")}: ${messageContent.join(", ")}.`);
    // }
    // if (!checksPassed || Object.keys(requestData).length === 1) {
    //   return;
    // }

    try {
      // if (query.superuser) {
      await ApiService.updateOrganizationSuperuser(requestData);
      // } else {
      //   const userRequestData = {
      //     userId: query.userId,
      //     organizationId: requestData.organizationId,
      //     address: requestData.address,
      //     city: requestData.city,
      //     postalCode: requestData.postalCode,
      //     country: requestData.country,
      //     contactPerson: requestData.contactPerson,
      //     phoneNumber: requestData.phoneNumber,
      //     landingPageUri: requestData.landingPageUri,
      //   };
      //   await ApiService.updateOrganizationAttributes(userRequestData);
      // }
      setMessage(t("successfully-updated-profile-fields"));
      setShow(true);
      setVariant("success");
      // clearProfileUpdate();
    } catch (e) {
      if (e.message.includes("email")) {
        setMessage(t("organization-with-the-same-email-already-exists"));
        setShow(true);
        setVariant("error");
      } else if (e.message.includes("name")) {
        setMessage(t("organization-with-the-same-name-already-exists"));
        setShow(true);
        setVariant("error");
      } else {
        setMessage(e.message);
        setShow(true);
        setVariant("error");
      }
    }
  };

  return (
    <>
      <Card id="company-profile" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">{t("company-profile")}</MDTypography>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("parent-organization")}
                placeholder={parentOrganization || ""}
                value={parentOrganization || ""}
                onChange={(e) => {
                  setParentOrganization(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("organization-type")}
                placeholder={organizationType || ""}
                value={organizationType || ""}
                onChange={(e) => {
                  setOrganizationType(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormField
                label={t("users-limit")}
                placeholder={usersLimit || ""}
                value={usersLimit || ""}
                onChange={(e) => {
                  setUsersLimit(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("organization-name")}
                placeholder={name || ""}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label={t("email-address")}
                placeholder={email || ""}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label={t("paymail-handle")}
                placeholder={paymailHandle}
                value={paymailHandle}
                onChange={(e) => {
                  setPaymailHandle(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                label={t("subdomains")}
                placeholder={subdomains}
                value={subdomains}
                onChange={(e) => {
                  setSubdomains(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("organization-number")}
                placeholder={number}
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("vat-number")}
                placeholder={vatNumber}
                value={vatNumber}
                onChange={(e) => {
                  setVatNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("vat-rate")}
                placeholder={vatRate}
                value={vatRate}
                onChange={(e) => {
                  setVatRate(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("address")}
                placeholder={address}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("city")}
                placeholder={city}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormField
                label={t("postal-code")}
                placeholder={postalCode}
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                label={t("country")}
                placeholder={country}
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("contact-person")}
                placeholder={contactPerson}
                value={contactPerson}
                onChange={(e) => {
                  setContactPerson(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("phone-number")}
                placeholder={phoneNumber}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                label={t("landing-page")}
                placeholder={landingPageUri}
                value={landingPageUri}
                onChange={(e) => {
                  setLandingPageUri(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox checked={checked} onChange={handleChangeCheckbox} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  {t("charge-organization")}
                </MDTypography>
              </MDBox>
            </Grid>

            <MDBox pt={4} pl={2}>
              <MDButton variant="gradient" color="primary" onClick={handleUpdateProfileSubmit}>
                {t("edit")}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("update-profile")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default BasicInfo;
