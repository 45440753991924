/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { useLocation, Link } from "react-router-dom";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import MDSnackbar from "components/MDSnackbar";
import bgImage from "assets/images/illustrations/unisot-bg.jpg";

function ValidateEmail() {
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      parentId: queryParams.get("parentId"),
      parentType: queryParams.get("parentType"),
      emailValidationSecret: queryParams.get("emailValidationSecret"),
    };

    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const responseData = await ApiService.userValidateEmail(params, signal);
        setSuccessMessage(responseData.message);
      } catch (e) {
        setErrorMessage(e.message);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [location]);

  return (
    <IllustrationLayout
      title="Email validaton"
      description={successMessage || errorMessage}
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            <MDTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default ValidateEmail;
