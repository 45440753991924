/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/company/components/Sidenav";
import BasicInfo from "layouts/pages/account/company/components/BasicInfo";
import Sessions from "layouts/pages/account/company/components/Sessions";
import DeleteAccount from "layouts/pages/account/company/components/DeleteAccount";
import ApiService from "api/ApiService";
import { isObjectEmpty } from "api/utils";

function Company() {
  const [userProfile, setUserProfile] = useState({});
  const [organizationProfile, setOrganizationProfile] = useState({});
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh(true);
  };
  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      try {
        await ApiService.getAvailableOrganizations();
      } catch (e) {
        console.error(e.message);
      }

      const params = {
        userId: userProfile._id,
        parentId: userProfile.organizations[0].organization,
        parentType: "ORGANIZATION",
      };
      try {
        const userProfileData = await ApiService.readUser(params);
        setOrganizationProfile(userProfileData);
      } catch (e) {
        console.error(e.message);
      }
    };
    !isObjectEmpty(userProfile) && apiCall();
  }, [userProfile, refresh]);

  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            {!isObjectEmpty(userProfile) && !isObjectEmpty(organizationProfile) && (
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BasicInfo profile={userProfile} organizationProfile={organizationProfile} />
                  </Grid>
                  <Grid item xs={12}>
                    <Sessions
                      profile={userProfile}
                      organizationProfile={organizationProfile}
                      handleRefresh={handleRefresh}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DeleteAccount
                      profile={userProfile}
                      organizationProfile={organizationProfile}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Company;
