/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";

function BasicInfo({ profile }) {
  const { t } = useTranslation();

  const [userLocation, setUserLocation] = useState(profile.country || "");
  const [userPhone, setUserPhone] = useState(profile.phoneNumber || "");
  const [userName, setUserName] = useState(profile.name || "");
  const [companyName, setCompanyName] = useState(
    profile.organizations && profile.organizations.length > 0 ? profile.organizations[0]?.name : ""
  );
  const [jobTitle, setJobTitle] = useState(
    profile.organizations && profile.organizations.length > 0
      ? profile.organizations[0]?.positions[0]?.name
      : ""
  );
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleSetLocation = (e) => {
    setUserLocation(e.target.value);
  };

  const handleSetUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleSetUserPhone = (e) => {
    setUserPhone(e.target.value);
  };
  const handleSetCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleSetJobTitle = (e) => {
    setJobTitle(e.target.value);
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/profile/profile-overview`);
    return url.toString();
  };

  const handleResendValidationLink = async () => {
    try {
      const responseData = await ApiService.sendUserValidateEmailLink({
        userId: profile._id,
        rootUrl: getFrontendUrl(),
      });
      setShow(true);
      setVariant("info");
      setMessage(responseData.message);
    } catch (e) {
      setShow(true);
      setVariant("error");
      setMessage(e.message);
    }
  };

  const handleUpdateProfile = async () => {
    const requestData = {
      userId: profile._id,
    };
    if (userName.length > 0 && userName !== profile.name) {
      requestData.name = userName;
    }
    if (userPhone.length > 0 && userPhone !== profile.phoneNumber) {
      requestData.phoneNumber = userPhone;
    }
    if (userLocation.length > 0 && userLocation !== profile.country) {
      requestData.country = userLocation;
    }
    if (jobTitle.length > 0 && jobTitle !== profile.organizations[0]?.positions[0]?.name) {
      requestData.companyPosition = jobTitle;
    }

    try {
      await ApiService.updateUser(requestData);
      setShow(true);
      setVariant("info");
      setMessage(t("user-updated"));
    } catch (e) {
      console.error(e);
      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">{t("basic-info")}</MDTypography>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label={t("name")}
                placeholder={userName || ""}
                value={userName || ""}
                onChange={handleSetUserName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label={t("email")}
                placeholder={profile.email || ""}
                value={profile.email || ""}
                inputProps={{ type: "email" }}
                disabled
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormField
                label={t("company-name")}
                placeholder={companyName || ""}
                value={companyName || ""}
                onChange={handleSetCompanyName}
                disabled
              />
            </Grid> */}
            {/*  <Grid item xs={12} sm={6}>
              <FormField
                label={t("job-title")}
                placeholder={jobTitle || ""}
                value={jobTitle || ""}
                onChange={handleSetJobTitle}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormField
                label={t("your-location")}
                placeholder={userLocation || ""}
                onChange={handleSetLocation}
                value={userLocation || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label={t("phone-number")}
                placeholder={userPhone}
                value={userPhone}
                onChange={handleSetUserPhone}
              />
            </Grid>
            <MDBox pt={4} pl={2}>
              <MDButton variant="gradient" color="primary" onClick={handleUpdateProfile}>
                {t("update-profile")}
              </MDButton>
              <MDButton variant="text" color="primary" onClick={handleResendValidationLink}>
                {t("resend-validation-link")}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("update-profile")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default BasicInfo;
