/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ApiService from "api/ApiService";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";

function ChangePassword({ profile }) {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const passwordRequirements = [
    t("one-special-characters"),
    t("min-6-characters"),
    t("one-number-2-are-recommended"),
    t("change-it-often"),
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const handlePassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleChangePassword = async () => {
    const validPasswordLength = newPassword.length > 6;
    const passwordsMatch = newPassword === confirmPassword;
    const checksPassed = validPasswordLength && passwordsMatch;
    if (!checksPassed) {
      const messageContent = [];
      if (!validPasswordLength) {
        messageContent.push(t("password-min-length"));
      }
      if (!passwordsMatch) {
        messageContent.push(t("passwords-not-match"));
      }
      setVariant("warning");
      setMessage(`${t("invalid-information-provided")}: ${messageContent}`);
      setShow(true);
      return;
    }
    try {
      await ApiService.updateUser({ password: newPassword, userId: profile._id });
      setVariant("info");
      setMessage(t("successfully-updated-password"));
      setShow(true);
    } catch (e) {
      console.error(e);
      setVariant("warning");
      setMessage(e.message);
      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Card id="change-password">
        <MDBox p={3}>
          <MDTypography variant="h5">{t("change-password-0")}</MDTypography>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDInput
                value={newPassword}
                onChange={handlePassword}
                fullWidth
                label={t("new-password")}
                inputProps={{ type: "password", autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                value={confirmPassword}
                onChange={handleConfirmPassword}
                fullWidth
                label={t("confirm-new-password")}
                inputProps={{ type: "password", autoComplete: "" }}
              />
            </Grid>
          </Grid>
          <MDBox mt={6} mb={1}>
            <MDTypography variant="h5">{t("password-requirements")}</MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="body2" color="text">
              {t("please-follow-this-guide-for-a-strong-password")}
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
            <MDBox ml="auto">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                onClick={handleChangePassword}
              >
                {t("update-password")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("change-password")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default ChangePassword;
