/* eslint-disable spaced-comment */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { useNavigate, Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import Checkbox from "@mui/material/Checkbox";
import bgImage from "assets/images/illustrations/unisot-bg.jpg";

function Illustration() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [requireToken, setRequireToken] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyPosition, setCompanyPosition] = useState("");
  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [organizationError, setOrganizationError] = useState("");

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const showErrorMessage = (messageContent) => {
    if (!requireToken) {
      if (messageContent === "Invalid 2FA token!") {
        setRequireToken(true);
        return setMessage("2fa-qr-code-secret");
      }
    }
    if (messageContent === "User does not exist!") {
      return setMessage("User doesnt exist");
    }
    if (messageContent === "Invalid password!") {
      return setMessage("Incorrect password");
    }
    return setMessage(messageContent);
  };

  const checkOrganization = (selectedOrganization) => {
    const company = availableOrganizations.find(
      (org) => org.toLowerCase() === selectedOrganization.toLowerCase()
    );
    if (company) {
      return company;
    }
    return false;
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/email-sign-in`);
    return url.toString();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validName = customerName.length > 0;
    const validEmail = isEmail(email);
    //const validOrganization = checkOrganization(selectedOrganization);
    const validPasswordLength = password.length > 6;
    const passwordsMatch = password === confirmPassword;
    const termsConditionsChecked = checked === true;
    const checksPassed =
      validName &&
      validEmail &&
      // validOrganization &&
      validPasswordLength &&
      passwordsMatch &&
      termsConditionsChecked;

    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        setNameError("Name min length");
        messageContent.push("Name min length");
      }
      if (!validEmail) {
        setEmailError("Invalid email");
        messageContent.push("Invalid email");
      }
      // if (!validOrganization) {
      //   setOrganizationError("Invalid company name");
      //   messageContent.push("Invalid company name");
      // }
      if (!validPasswordLength) {
        setPasswordError("Password min length");
        messageContent.push("Password min length");
      }
      if (!passwordsMatch) {
        setConfirmPasswordError("Passwords not match");
        messageContent.push("passwords not match");
      }
      if (!termsConditionsChecked) {
        setMessage("Agree terms conditions");
      }

      return;
    }

    try {
      await ApiService.createUser({
        name: customerName,
        email,
        password,
        // companyName: validOrganization,
        // companyPosition,
        services: ApiService.defaultRegistrationServices,
        frontendUrl: getFrontendUrl(),
        roles: ApiService.defaultRegistrationRoles,
        enabledMagicLinkLogin: true,
        trialAccount: true,
        notifyLowCredits: true,
        lowCreditsThreshold: "0",
      });
      //navigate("/");
      setShowSuccessMessage(true);
    } catch (e) {
      if (e.message.includes("email")) {
        showErrorMessage("Email already exists");
      } else if (e.message.includes("name")) {
        showErrorMessage("Name already exists");
      } else {
        showErrorMessage(e.message);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        let organizations = await ApiService.getAvailableOrganizations(signal);
        organizations = organizations.map((organization) => organization.name);
        organizations.sort();
        setAvailableOrganizations(organizations);
      } catch (e) {
        console.error(e);
        showErrorMessage(e.message);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  return (
    <IllustrationLayout title="Sign Up" description="" illustration={bgImage}>
      {showSuccessMessage ? (
        <MDBox>
          <MDTypography variant="body2">{`Dear ${customerName},`}</MDTypography>
          <MDTypography variant="body2" mt={2}>
            {`Thank you for signing up to SignOnChain.id! We're thrilled to have you
          on board. To ensure the security of your account and enable all the features, we kindly
          request you to verify your email address. You should have received an email with a
          verification link. Please click on the link to complete the registration process. If you
          haven't received the email, please check your spam or junk folder. Occasionally, it may
          take a few minutes for the email to arrive. If you encounter any issues or need further
          assistance, feel free to contact us at admin@signonchain.id, we will be happy to assist
          you. `}
          </MDTypography>
          <MDTypography variant="body2" mt={2}>
            Best regards,
          </MDTypography>
          <MDTypography variant="body2" mt={2}>
            The SignOnChain Team
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox component="form" role="form">
          {message.length > 0 && (
            <MDBox mb={2}>
              <MDTypography variant="caption" color="warning">
                {message}
              </MDTypography>
            </MDBox>
          )}
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              fullWidth
              value={customerName}
              onChange={(e) => {
                setNameError("");
                setCustomerName(e.target.value);
              }}
              error={nameError.length > 0}
              helperText={nameError}
            />
          </MDBox>
          {/* <MDBox mb={2}>
   <MDInput
     type="text"
     label="Company name"
     fullWidth
     value={selectedOrganization}
     onChange={(e) => {
       setOrganizationError("");
       setSelectedOrganization(e.target.value);
     }}
     error={organizationError.length > 0}
     helperText={organizationError}
   />
 </MDBox>
 <MDBox mb={2}>
   <MDInput
     type="text"
     label="Job title"
     fullWidth
     value={companyPosition}
     onChange={(e) => {
       setCompanyPosition(e.target.value);
     }}
   />
 </MDBox> */}
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => {
                setMessage("");
                setEmail(e.target.value);
              }}
              error={emailError.length > 0}
              helperText={emailError}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => {
                setPasswordError("");
                setPassword(e.target.value);
              }}
              error={passwordError.length > 0}
              helperText={passwordError}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Confirm password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPasswordError("");
                setConfirmPassword(e.target.value);
              }}
              error={confirmPasswordError.length > 0}
              helperText={confirmPasswordError}
            />
          </MDBox>
          <MDBox display="flex" alignItems="center" ml={-1}>
            <Checkbox checked={checked} onChange={handleChangeCheckbox} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;I agree the&nbsp;
            </MDTypography>
            <MDTypography
              component="a"
              href="https://signonchain.id/terms-and-conditions/"
              variant="button"
              fontWeight="bold"
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              textGradient
            >
              Terms and Conditions
            </MDTypography>
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton
              type="submit"
              variant="gradient"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              sign up
            </MDButton>
          </MDBox>
          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              You have an account?{" "}
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Sign in
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </IllustrationLayout>
  );
}

export default Illustration;
