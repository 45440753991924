/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Tooltip } from "@mui/material";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import ApiService from "api/ApiService";

function Recipients({
  title,
  profiles,
  shadow,
  handleAction,
  loading,
  fileDetails,
  handleRefreshDoc,
  ownerActions,
  address,
}) {
  const { t } = useTranslation();

  const [openAddReceiversModal, setOpenAddReceiversModal] = useState(false);
  const [receiversList, setReceiversList] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [snackTitle, setSnackTitle] = useState("");
  const [radioValue, setRadioValue] = useState(t("needs-to-sign"));

  useEffect(() => {
    setRadioValue(t("needs-to-sign"));
  }, [t]);

  useEffect(() => {
    const apiOperations = async () => {
      const { signal } = new AbortController();
      try {
        const resp = await ApiService.loginRequired(signal, false);
        setUserProfile(resp);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
  }, []);

  const handleOpenAddReceiversModal = () => {
    setOpenAddReceiversModal(true);
  };
  const handleCloseAddReceiverModal = () => {
    setOpenAddReceiversModal(false);
  };

  const handleRemoveReceiver = (email, type) => {
    setReceiversList(receiversList.filter((rec) => !(rec.email === email && rec.type === type)));
  };

  const handleChangeRadio = (e, value) => {
    setRadioValue(value);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSetEmailAddress = (e) => {
    if (e.target.value === userProfile.email) {
      setEmailAddress(e.target.value);
      setErrorMessage(t("cant-add-yourself-as-receiver"));
      return;
    }
    setErrorMessage("");
    setEmailAddress(e.target.value);
  };

  const handleSaveReceiver = () => {
    if (emailAddress.length === 0) {
      setShow(true);
      setMessage(t("email-address-required"));
      setVariant("error");
      return;
    }
    if (emailAddress === userProfile.email) {
      setErrorMessage("");
      return;
    }
    setReceiversList([
      ...receiversList,
      {
        email: emailAddress,
        type: radioValue === t("needs-to-sign") ? "SIGN" : "READ",
      },
    ]);
    setEmailAddress("");
  };

  const getFrontendUrlMagicLink = () => {
    const queryParameters = new URLSearchParams({
      data_id: fileDetails.data_id,
      sender: fileDetails.sender,
      receiver: fileDetails.receiver,
      hash: fileDetails.hash,
    }).toString();

    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/email-sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const handleInviteReceivers = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData;
    const deploymentId = userProfile.endpoints.filter((ser) => ser.name === "SDC")[0].deploymentId;
    // eslint-disable-next-line no-restricted-syntax
    for (const elem of receiversList) {
      // check if user exists
      const userExists = await ApiService.getUserExists({ deploymentId, email: elem.email });
      if (!userExists.exists) {
        try {
          await ApiService.createGuestUser({
            name: elem.email,
            email: elem.email,
            enabledMagicLinkLogin: true,
            guestAccount: true,
            trialAccount: true,
            services: ApiService.defaultRegistrationServices,
            roles: ApiService.defaultRegistrationRoles,
            disableNotifications: "true",
            notifyLowCredits: true,
            lowCreditsThreshold: "0",
          });
        } catch (e) {
          console.error(e);
        }
      }
      try {
        returnData = await ApiService.addAccessRights(
          {
            parentId: fileDetails._id,
            parentType: "PACKAGE",
            type: elem.type,
            email: elem.email,
            endOfLife: "-1",
          },
          signal
        );
        setShow(true);
        setSnackTitle(t("add-receiver"));
        setMessage(t("successfully-invited-receiver"));
        setVariant("primary");
      } catch (e) {
        console.error(e);
        setShow(true);
        setSnackTitle(t("add-receiver"));
        setVariant("error");
        setMessage(e.message);
        return;
      }

      const params = {
        parentId: fileDetails._id,
        parentType: "PACKAGE",
        accessRightsId: returnData.accessRights.filter((user) => user.email === elem.email)[0]._id,
        frontendUrl: getFrontendUrlMagicLink(),
        populateMagicLink: "true",
      };

      try {
        await ApiService.inviteAccessRights(params, signal);
      } catch (e) {
        console.error(e);
        setShow(true);
        setSnackTitle(t("add-receiver"));
        setVariant("error");
        setMessage(e.message);
      }
    }
    handleCloseAddReceiverModal();
    handleRefreshDoc();
    setReceiversList([]);
  };

  const handleResendInvite = async (profile) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const params = {
      parentId: fileDetails._id,
      parentType: "PACKAGE",
      accessRightsId: profile._id,
      frontendUrl: getFrontendUrlMagicLink(),
      populateMagicLink: "true",
    };

    try {
      await ApiService.inviteAccessRights(params, signal);
      setShow(true);
      setSnackTitle(t("invite-receiver"));
      setMessage(t("successfully-resend-invitation"));
      setVariant("primary");
    } catch (e) {
      console.error(e);
      setShow(true);
      setSnackTitle(t("invite-receiver"));

      setVariant("error");
      setMessage(e.message);
    }
  };

  const renderProfiles = profiles
    ? profiles?.map((profile) => {
        let hasInvited = false;
        if (profile.sender === address) {
          hasInvited = true;
        }
        return (
          <MDBox key={profile._id} component="li" display="flex" alignItems="center" mb={2}>
            <MDBox mr={2}>
              <MDAvatar bgColor="info">
                <Icon fontSize="lg">person</Icon>
              </MDAvatar>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <MDTypography variant="button" fontWeight="medium" style={{ wordBreak: "break-all" }}>
                {profile.email}
              </MDTypography>
              <MDBox
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="center"
              >
                <MDTypography variant="caption" color="text" style={{ marginTop: "14px" }}>
                  {`${t("can")}: ${t(profile.type?.toLowerCase())}`}
                </MDTypography>

                {(ownerActions || hasInvited) && (
                  <>
                    <Tooltip title={t("remove-receiver")}>
                      <MDButton
                        variant="text"
                        color="primary"
                        onClick={() => handleAction(profile._id)}
                      >
                        <Icon>cancel</Icon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title={t("resend-email-invite")}>
                      <MDButton
                        variant="text"
                        color="primary"
                        onClick={() => handleResendInvite(profile)}
                      >
                        <Icon>send</Icon>
                      </MDButton>
                    </Tooltip>
                  </>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        );
      })
    : loading &&
      [{}]?.map(() => (
        <MDBox component="li" display="flex" alignItems="center" mb={2}>
          <MDBox mr={2}>
            <MDAvatar bgColor="info">
              <Icon fontSize="lg">person</Icon>
            </MDAvatar>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              <Skeleton variant="text" sx={{ width: "100px", fontSize: "0.875rem" }} />
            </MDTypography>
            <MDTypography variant="caption" color="text">
              <Skeleton variant="text" sx={{ width: "100px", fontSize: "0.75rem" }} />
            </MDTypography>
          </MDBox>
        </MDBox>
      ));

  return (
    <>
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" flexDirection="row">
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>

          {true && (
            <MDButton
              variant="text"
              color="primary"
              onClick={handleOpenAddReceiversModal}
              style={{ marginLeft: "auto", marginTop: "-6px" }}
              disabled={fileDetails.status === "EXPIRED" || document.status === "DELETED"}
            >
              <Icon>add_icon</Icon>
              {t("add")}
            </MDButton>
          )}
        </MDBox>
        <MDBox pt={1}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {renderProfiles}
          </MDBox>
        </MDBox>
      </Card>

      <Dialog open={openAddReceiversModal} onClose={handleCloseAddReceiverModal}>
        <DialogTitle>{t("add-receivers")}</DialogTitle>

        <DialogContent>
          <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
            <MDBox pl={2} pt={2}>
              <MDTypography variant="caption">
                {t("you-can-add-one-or-more-receivers-by-clicking-the-button-add")}
              </MDTypography>
            </MDBox>
            {receiversList.map((receiver) => (
              <Grid item xs={12}>
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                >
                  <MDBox>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                      {receiver.email}
                    </MDTypography>
                    <MDTypography variant="caption" fontWeight="regular" color="text">
                      {t(receiver.type?.toLowerCase())}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      lineHeight={1}
                      ml={3}
                      sx={{ cursor: "pointer" }}
                    >
                      <MDButton onClick={() => handleRemoveReceiver(receiver.email, receiver.type)}>
                        <Icon fontSize="small">remove</Icon>
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
            ))}

            <Grid item xs={12} sm={7} lg={7} xl={7} style={{ paddingTop: "0px" }}>
              <MDInput
                variant="standard"
                value={emailAddress}
                error={errorMessage.length > 0}
                onChange={handleSetEmailAddress}
                fullWidth
                label={t("email-address")}
                inputProps={{ type: "email", autoComplete: "" }}
              />
            </Grid>

            <Grid item xs={12} sm={5} lg={5} xl={5}>
              <Autocomplete
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    paddingRight: "0px!important",
                  },
                }}
                defaultValue={radioValue}
                value={radioValue}
                onChange={handleChangeRadio}
                options={[t("needs-to-sign"), t("send-a-copy")]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
            <MDBox pt={1}>
              <MDButton variant="text" color="info" onClick={handleSaveReceiver}>
                <Icon>add</Icon>
                {t("add")}
              </MDButton>
            </MDBox>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseAddReceiverModal}>{t("cancel")}</MDButton>
          <MDButton variant="gradient" color="primary" size="small" onClick={handleInviteReceivers}>
            {t("add-invite")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title={snackTitle}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

// Setting default props for the ProfilesList
Recipients.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
Recipients.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
};

export default Recipients;
