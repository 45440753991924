/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Invoice from "layouts/pages/account/billing/components/Invoice";
import { formatDateTime } from "api/utils";
import InvoiceSkelet from "../Invoice/InvoiceSkelet";

function Invoices({ loading, handleClick, handleInvoice, invoices }) {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          {t("invoices")}
        </MDTypography>
        <MDButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={(e) => handleClick(e, invoices)}
        >
          {t("view-all")}
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {loading ? (
            <>
              <InvoiceSkelet />
              <InvoiceSkelet />
              <InvoiceSkelet />
              <InvoiceSkelet />
            </>
          ) : (
            <>
              {invoices.slice(0, 4).map((invoice) => (
                <Invoice
                  date={formatDateTime(invoice.invoiceDate)}
                  id={invoice._id}
                  price={`${invoice.amount} €`}
                  handleInvoice={handleInvoice}
                />
              ))}
            </>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Invoices;
