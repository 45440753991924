/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { useLocation, Link } from "react-router-dom";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import MDSnackbar from "components/MDSnackbar";
import bgImage from "assets/images/illustrations/unisot-bg.jpg";

function Reset() {
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const handleSubmit = async () => {
    const validPasswordLength = password.length > 6;
    const passwordsMatch = password === confirmPassword;
    const checksPassed = validPasswordLength && passwordsMatch;
    if (!checksPassed) {
      const messageContent = [];
      if (!validPasswordLength) {
        messageContent.push("Password min length");
      }
      if (!passwordsMatch) {
        messageContent.push("Passwords not match");
      }
      setVariant("warning");
      setMessage(`"Invalid information provided": ${messageContent.join(", ")}.`);
      setShow(true);
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const params = {
      userId: queryParams.get("userId"),
      passwordResetSecret: queryParams.get("passwordResetSecret"),
      password,
    };
    try {
      const responseData = await ApiService.resetUserPassword(params);
      setVariant("primary");
      setMessage(responseData.message);
      setShow(true);
    } catch (e) {
      setVariant("warning");
      setMessage(e.message);
      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <IllustrationLayout
      title="Reset password"
      description="Enter new password and confirm it"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Confirm Password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="primary"
            size="large"
            fullWidth
            onClick={handleSubmit}
          >
            Change password
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            <MDTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDSnackbar
        icon="notifications"
        title="Change password"
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </IllustrationLayout>
  );
}

export default Reset;
