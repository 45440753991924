/* eslint-disable react/destructuring-assignment */
import { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";

export default function StripeCheckout(props) {
  const stripe = useStripe();

  useEffect(() => {
    let isMounted = true;

    const apiOperations = async () => {
      try {
        const { error } = await stripe.redirectToCheckout({
          sessionId: props.paymentSessionId,
        });
        if (error) {
          console.error(error);
        }
        props.handleRefresh();
      } catch (e) {
        console.error(e);
      }
    };

    isMounted && !!stripe && !props.showDialog && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [stripe, props]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
