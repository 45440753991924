/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Grid from "@mui/material/Grid";
import ApiService from "api/ApiService";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import StripeCheckout from "../Checkout/StripeCheckout";

const stripePromise = loadStripe(ApiService.stripePublicKey);
const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        rail: {
          color: "#017f98",
        },
        track: {
          color: "#017f98",
        },
        thumb: {
          color: "#017f98",
        },
      },
    },
  },
});
function TopUpCard({ title, description }) {
  const { t, i18n } = useTranslation();

  const [design, setDesign] = useState(true);
  const [code, setCode] = useState(false);
  const [sliderValue, setSliderValue] = useState(10);
  const [paymentSession, setPaymentSession] = useState({});

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(
    ApiService.serviceSubscriptionPaymentMethods.creditCard
  );

  const handleClose = () => {
    setShow(false);
  };

  const handleRefresh = () => {
    setPaymentSession({});
  };

  const handleSetDesign = () => {
    setPaymentMethod(ApiService.serviceSubscriptionPaymentMethods.creditCard);
    setCode(!code);
    return setDesign(!design);
  };

  const handleSetCode = () => {
    setPaymentMethod(ApiService.serviceSubscriptionPaymentMethods.wallet);
    setDesign(!design);
    return setCode(!code);
  };

  const handleSlider = (event, newValue) => {
    if (typeof newValue === "number") {
      setSliderValue(newValue);
    }
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/account/billing`);
    return url.toString();
  };

  const handlePayViaStripe = async () => {
    try {
      const params = {
        paymentProvider:
          paymentMethod === ApiService.serviceSubscriptionPaymentMethods.creditCard
            ? ApiService.paymentProvider.stripe
            : ApiService.paymentProvider.handCash,
        amount: sliderValue.toString(),
        frontendUrl: getFrontendUrl(),
      };

      const session = await ApiService.startBillingTopUpPaymentSessionId(params);
      if (session.paymentRequestUrl) {
        window.location.replace(session.paymentRequestUrl);
      }
      setPaymentSession({ session });
    } catch (e) {
      setMessage(e.message);
      setVariant("error");
      setShow(true);
    }
  };

  const customButtonStyles = ({
    functions: { pxToRem },
    borders: { borderWidth },
    typography: { size },
  }) => ({
    width: pxToRem(120),
    height: pxToRem(100),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    // "&.MuiButton-contained, &.MuiButton-contained:hover": {
    //   boxShadow: "none",
    //   border: `${borderWidth[2]} solid ${transparent.main}`,
    // },

    // "&:hover": {
    //   backgroundColor: `${transparent.main} !important`,
    //   border: `${borderWidth[2]} solid ${info.main} !important`,
    //   color: rgba(info.main, 0.75),
    // },

    "& .material-icons-round": {
      fontSize: `${size["3xl"]} !important`,
    },
  });

  return (
    <Card>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25} pt={3}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>

      <MDBox mt={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <MDBox textAlign="center">
              <MDButton
                color="primary"
                onClick={handleSetDesign}
                variant={design ? "contained" : "outlined"}
                sx={customButtonStyles}
              >
                <Icon sx={{ color: design ? "white" : "inherit" }}>credit_card_icon</Icon>
              </MDButton>
              <MDTypography variant="h6" sx={{ mt: 1 }}>
                {t("credit-card")}
              </MDTypography>
            </MDBox>
          </Grid>
          {i18n.language !== "zh" && (
            <Grid item xs={12} sm={6}>
              <MDBox textAlign="center">
                <MDButton
                  color="primary"
                  variant={code ? "contained" : "outlined"}
                  onClick={handleSetCode}
                  sx={customButtonStyles}
                >
                  <Icon sx={{ color: design ? "white" : "inherit" }}>account_balance_wallet</Icon>
                </MDButton>
                <MDTypography variant="h6" sx={{ mt: 1 }}>
                  {t("wallet")}
                </MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        {description && (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            {description}
          </MDTypography>
        )}
        <MDBox mt={3} mb={3}>
          <ThemeProvider theme={theme}>
            <Slider
              size="small"
              defaultValue={10}
              aria-label="Small"
              min={1}
              step={1}
              max={50}
              valueLabelDisplay="auto"
              value={sliderValue}
              onChange={handleSlider}
              color="primary"
            />
          </ThemeProvider>
        </MDBox>
        {sliderValue && (
          <MDTypography variant="h5" fontWeight="medium" mb={2}>
            {`${sliderValue} €`}
          </MDTypography>
        )}
        <MDButton color="primary" variant="outlined" onClick={handlePayViaStripe}>
          {paymentMethod === "CREDIT CARD" ? t("pay-via-stripe") : t("pay-via-handcash")}
        </MDButton>
      </MDBox>
      <MDSnackbar
        icon="notifications"
        title={t("top-up")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
      {paymentSession.session && (
        <Elements stripe={stripePromise}>
          <StripeCheckout
            paymentSessionId={paymentSession.session.paymentSessionId}
            showDialog={paymentSession.showDialog}
            handleRefresh={handleRefresh}
          />
        </Elements>
      )}
    </Card>
  );
}

TopUpCard.defaultProps = {
  description: "",
};

TopUpCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default TopUpCard;
