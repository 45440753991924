/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ApiService from "api/ApiService";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import PDFPreview from "layouts/pages/add-file/PDFPreview";
import MDBox from "components/MDBox";
import { isObjectEmpty, formatDate } from "api/utils";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import MDAlert from "components/MDAlert";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import moment from "moment";
import { isMobile } from "react-device-detect";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import OrderSummary from "./components/OrderSummary";
import Recipients from "./components/Recipients";
import Signatures from "./components/Signatures";

function DocumentDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const [documentDetails, setDocumentDetails] = useState({});
  const [showDocument, setShowDocument] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [pdfReport, setPdfReport] = useState({ data: new Uint8Array(0) });
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [showOwnerActions, setShowOwnerActions] = useState(true);
  const [SDCAddress, setSDCAddress] = useState("");
  const [timeDuration, setTimeDuration] = useState(1);
  const [timeValue, setTimeValue] = useState("year(s)");
  const [fileTime, setFileTime] = useState(moment().add(7, "days"));
  const [title, setTitle] = useState("");
  const [profile, setProfile] = useState({});
  const [packageMetadata, setPackageMetadata] = useState("");
  const [packageMetadataName, setPackageMetadataName] = useState("");
  const [packageMetadataType, setPackageMetadataType] = useState(ApiService.metadataTypes.string);
  const [lookupUsers, setLookupUsers] = useState({});
  const [uploadNotification, setShowUploadNotification] = useState(false);
  const [openExtendEolModal, setOpenExtendEolModal] = useState(false);
  const [disableSign, setDisableSign] = useState(true);
  const [pdfPages, setPdfPages] = useState(1);
  const [metadataModal, setOpenMetadataModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [accessRead, setAccessRead] = useState(false);
  const [scale, setScale] = useState(1.0);
  const handleZoomIn = () => {
    setScale((prev) => prev + 0.2);
  };

  const handleZoomOut = () => {
    setScale((prev) => prev - 0.2);
  };

  const handleChangeFileTime = (e) => {
    setTimeDuration(e.target.value);
  };
  const handleChangeTimeValue = (e, value) => {
    setTimeValue(value);
  };

  useEffect(() => {
    if (timeValue === t("days")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "days"));
    }
    if (timeValue === t("weeks")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "weeks"));
    }
    if (timeValue === t("months")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "months"));
    }
    if (timeValue === t("years")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "years"));
    }
  }, [timeDuration, timeValue]);

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = () => {
    setShowDocument(true);
  };

  const handleClickDetails = () => {
    setShowDetails(true);
  };
  const handleHideClickDetails = () => {
    setShowDetails(false);
  };
  const handleHideDocument = () => {
    setShowDocument(false);
  };
  // Retrieve package search parameters
  const query = new URLSearchParams(location.search);
  const packageParams = {
    data_id: query.get("data_id"),
    hash: query.get("hash"),
    sender: query.get("sender"),
    receiver: query.get("receiver"),
  };

  const queryParameters = new URLSearchParams({
    data_id: packageParams.data_id,
    sender: packageParams.sender,
    receiver: packageParams.receiver,
    hash: packageParams.hash,
  }).toString();

  useEffect(() => {
    const getUserSignature = async () => {
      try {
        await ApiService.readUserDocument({
          userId: profile._id,
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: ApiService.documentTypes.signature,
        });
      } catch (e) {
        setShowUploadNotification(true);
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && getUserSignature();
  }, [profile]);

  useEffect(() => {
    const getUsers = async () => {
      const responseData = await ApiService.getAllUsers({});
      const users = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of responseData) {
        users[entry.address] = entry.user.email;
      }
      setLookupUsers(users);
    };
    getUsers();
  }, []);

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const handleDownloadReport = (download = true) => {
    const handlePreviewFile = async (userId, parameters, signal) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(reportParams, signal);

      const checkConfirmed = () => {
        if (documentDetails.accessRights.length === 0 && documentDetails.signatures.length > 0) {
          return t("confirmed");
        }
        const signSet = new Set();
        documentDetails.signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

        const accessSet = new Set();
        documentDetails.accessRights
          .filter((access) => access.type === "SIGN")
          .map((acc) => accessSet.add(acc.email));

        if (accessSet.size === 0 && signSet.size > 0) {
          return t("confirmed");
        }
        if (
          (signSet.size === accessSet.size &&
            !accessSet.has(profile.email) &&
            !signSet.has(profile.email) &&
            documentDetails.accessRights.filter((acc) => acc.email === profile.email).length ===
              0) ||
          signSet.size === accessSet.size + 1
        ) {
          return t("confirmed");
        }
        return "";
      };

      if (download) {
        const titlePrefix = checkConfirmed();
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(responseData);
        a.download = `${documentDetails.title}${titlePrefix.length > 0 ? ` - ${titlePrefix}` : ""}${
          documentDetails.title.includes("pdf") ? "" : ".pdf"
        }`;
        a.click();
      } else {
        const fileBuffer = await responseData.arrayBuffer();
        const uint8View = new Uint8Array(fileBuffer);
        setPdfReport({ data: uint8View });
      }
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentDetails._id,
        parentType: "PACKAGE",
        frontendUrl: getFrontendUrl(),
        creatorName: "SignOnChain",
        documentTitle: "SignOnChain Report",
        reportTitle: "report",
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(reportParams, signal);
      } catch (e) {
        console.error("Error downloading package");
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
      }
    };
    handlePrepareFileDownload();
  };

  const handleDeleteFile = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.deleteFile(
        {
          data_id: documentDetails.data_id,
          hash: documentDetails.hash,
          sender: documentDetails.sender,
          receiver: documentDetails.receiver,
        },
        signal
      );
      setTitle(t("delete-file"));
      setShow(true);
      setVariant("info");
      setMessage(t("deleted-file"));
    } catch (e) {
      console.error(e);
      setShow(true);
      setTitle(t("delete-file"));
      setVariant("warning");
      setMessage(e.message);
      return;
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("delete-file"));
          setVariant("warning");
          setMessage(t("failed"));
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          navigate("/documents");
        }
      }
      if (signal.aborted) {
        setShow(true);
        setTitle(t("delete-file"));
        setVariant("warning");
        setMessage(t("aborted"));
      }
    } catch (e) {
      console.error(e);
      setShow(true);
      setTitle(t("delete-file"));
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const handleRefresh = useCallback(
    async (returnData = undefined, signal = undefined) => {
      let documentData;
      const query = new URLSearchParams(location.search);
      const packageParams = {
        data_id: query.get("data_id"),
        hash: query.get("hash"),
        sender: query.get("sender"),
        receiver: query.get("receiver"),
      };

      if (!returnData) {
        setLoadingDetails(true);
        try {
          documentData = await ApiService.getPackageDetails(packageParams, signal);
        } catch (e) {
          console.error(e);
          setShow(true);
          setTitle(t("document-details"));
          setVariant("warning");
          setMessage(e.message);
          setShowError(true);
        } finally {
          setLoadingDetails(false);
        }
      } else {
        documentData = returnData;
      }

      setDocumentDetails(documentData);
      setFileTime(moment(documentData.endOfLife).add(1, "years"));

      const { address } = await ApiService.getPublicKey({}, signal);
      setSDCAddress(address);
      setShowOwnerActions(address === documentData.sender);
    },
    [location.search]
  );

  useEffect(() => {
    !isObjectEmpty(documentDetails) && handleDownloadReport(false);
  }, [documentDetails]);

  const handleDeleteAccess = async (id) => {
    const params = {
      parentId: documentDetails._id,
      parentType: "PACKAGE",
      accessRightsId: id,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.deleteAccessRights(params, signal);
      setShow(true);
      setTitle(t("file-access"));
      setVariant("info");
      setMessage(t("removed-access"));
    } catch (e) {
      setShow(true);
      setTitle(t("file-access"));
      setVariant("warning");
      setMessage(e.message);
    }
    await handleRefresh();
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setProfile(userProfile);

        userProfile.validatedDocuments &&
          userProfile.validatedEmail &&
          (await handleRefresh(undefined, signal)); //  todo
      } catch (e) {
        console.error(e);
        setShow(true);
        setTitle(t("file-access"));
        setVariant("warning");
        setMessage(e.message);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [handleRefresh]);

  const handleSign = async () => {
    // Deletion parameters
    const params = {
      parentId: documentDetails._id,
      parentType: "PACKAGE",
      access: documentDetails.access,
      frontendUrl: getFrontendUrl(),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      setShow(true);
      setTitle(t("sign-document"));
      setVariant("error");
      setMessage(e.message);
    }

    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          console.error("Error downloading package");
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setTitle(t("sign-document"));
          setMessage(t("document-successfully-signed"));

          await handleRefresh();
        }
      }
      if (signal.aborted) {
        console.error("Operation aborted");
      }
    } catch (e) {
      console.error("Error downloading package");
      setShow(true);
      setTitle(t("sign-document"));
      setVariant("warning");
      setMessage(e.message);
    }
  };

  const handleOpenExtend = () => {
    setOpenExtendEolModal(true);
  };

  const handleCloseExtend = () => {
    setOpenExtendEolModal(false);
  };
  const handleExtendEol = async () => {
    const params = {
      data_id: query.get("data_id"),
      hash: query.get("hash"),
      receiver: query.get("receiver"),
      ttl: Math.ceil((fileTime.endOf("day") - moment()) / 1000.0),
    };

    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.updatePackageTTL(params);
    } catch (e) {
      console.error(e.message);
      setShow(true);
      setTitle(t("extend-eol"));
      setVariant("warning");
      setMessage(e.message);
    }

    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId };

      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("extend-eol"));
          setVariant("error");
          setMessage(t("error-extending-eol"));
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setTitle(t("extend-eol"));
          setMessage(t("successfully-extended-eol"));
        }
      }
      if (signal.aborted) {
        setShow(true);
        setVariant("error");
        setTitle(t("extend-eol"));
        setMessage(t("operation-aborted"));
        return;
      }
    } catch (e) {
      setShow(true);
      setVariant("error");
      setTitle(t("extend-eol"));
      setMessage(t("error-uploading-file"));
    }
    handleCloseExtend();
    await handleRefresh();
  };

  useEffect(() => {
    const checkCanSign = () => {
      const onlyRead = documentDetails.accessRights.filter(
        (access) => access.email === profile.email && access.type === "READ"
      );
      if (onlyRead.length > 0) {
        return setAccessRead(true);
      }
      const disable =
        documentDetails.signatures.filter((sign) => lookupUsers[sign.sender] === profile.email)
          .length > 0;
      return setDisableSign(disable);
    };
    !isObjectEmpty(profile) && !isObjectEmpty(documentDetails) && checkCanSign();
  }, [profile, documentDetails]);

  const handleSetPages = (nmbr) => {
    setPdfPages(nmbr - 1);
  };

  const deletePackageMetadataAsync = (data) => {
    const handlePackageMetadataDeletion = async () => {
      // Deletion parameters
      const params = {
        parentId: documentDetails._id,
        parentType: ApiService.parentTypes.package,
        metadataId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteMetadata(params, signal);
        handleRefresh();
      } catch (e) {
        console.error(e);
      }
    };

    handlePackageMetadataDeletion();
  };

  const prepareDataForTable = (metadata) =>
    metadata.map((elem) => ({
      user: `${lookupUsers[elem.user]} (${elem.user})`,
      date: formatDate(elem.date),
      type: elem.type,
      name: elem.name,
      metadata: elem.metadata,
      actions: (
        <MDBox mr={1}>
          <Tooltip title={t("delete")} placement="top">
            <MDButton
              color="warning"
              size="small"
              iconOnly
              circular
              onClick={() => {
                deletePackageMetadataAsync(elem);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      ),
    }));

  const structureDataForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("user"),
          accessor: "user",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("date"),
          accessor: "date",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("type"),
          accessor: "type",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("name"),
          accessor: "name",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("metadata"),
          accessor: "metadata",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(files),
    };
    return dataTableData;
  };

  const handleOpenMetadataModal = () => {
    setOpenMetadataModal(true);
  };
  const handleCloseMetadataModal = () => {
    setOpenMetadataModal(false);
  };

  const handleAddPackageMetadata = async () => {
    const packageMetadataNameParsed = packageMetadataName.trim();
    if (packageMetadataNameParsed.length === 0) {
      setTitle(t("add-metadata"));
      setVariant("error");
      setMessage(t("package-metadata-name-field-empty"));
      setShow(true);
      return;
    }
    const packageMetadataParsed = packageMetadata.trim();
    if (packageMetadataParsed.length === 0) {
      setTitle(t("add-metadata"));
      setVariant("error");
      setMessage(t("metadata-field-empty"));
      setShow(true);
      return;
    }
    let metadata;
    try {
      metadata =
        packageMetadataType === ApiService.metadataTypes.json
          ? JSON.parse(packageMetadataParsed)
          : packageMetadataParsed;
    } catch (e) {
      setTitle(t("add-metadata"));
      setVariant("error");
      setMessage(t("invalid-metadata-fields"));
      setShow(true);
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.addMetadata(
        {
          parentId: documentDetails._id,
          parentType: ApiService.parentTypes.package,
          name: packageMetadataNameParsed,
          type: packageMetadataType,
          metadata,
        },
        signal
      );
      handleCloseMetadataModal();
      handleRefresh();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showError ? (
        <MDBox my={6}>
          <MDBox sx={{ display: { xs: "none", md: "block" } }} style={{ textAlign: "center" }}>
            <MDTypography variant="h4" fontWeight="bold">
              {t("invalid-document-link")}
            </MDTypography>
            <MDBox pt={5}>
              <MDBox mb={3}>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  {t(
                    "the-document-you-are-trying-to-access-does-not-exists-There-can-be-several-reasons-to-his"
                  )}
                </MDTypography>
              </MDBox>

              <MDBox>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("the-documents-end-of-life-has-been-reached-and-document-has-been-purged")}
                  </MDTypography>
                </MDBox>

                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("you-no-longer-have-access-to-this-document")}
                  </MDTypography>
                </MDBox>

                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("the-link-you-clicked-is-incorrect")}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <>
          <MDBox my={6}>
            {showDocument ? (
              <>
                <MDBox style={{ display: "inline-flex" }}>
                  <MDBox mb={2}>
                    <MDButton variant="outlined" color="primary" onClick={handleHideDocument}>
                      {t("back-to-details")}
                    </MDButton>
                  </MDBox>
                  <MDBox mb={2} ml={1}>
                    <Tooltip
                      title={
                        accessRead
                          ? t("read-access")
                          : disableSign
                          ? t("you-already-signed-this-document")
                          : t("sign-document")
                      }
                    >
                      <span>
                        <MDButton
                          variant="contained"
                          color="primary"
                          onClick={handleSign}
                          disabled={accessRead || disableSign}
                        >
                          {t("sign")}
                        </MDButton>
                      </span>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                {!isMobile && (
                  <div style={{ float: "right" }}>
                    <Tooltip title={t("zoom-in")} placement="top">
                      <MDButton variant="outlined" color="info" onClick={handleZoomIn}>
                        <Icon>add</Icon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title={t("zoom-out")} placement="top">
                      <MDButton variant="outlined" color="info" onClick={handleZoomOut}>
                        <Icon>remove</Icon>
                      </MDButton>
                    </Tooltip>
                  </div>
                )}
                {pdfReport.data.length > 0 && !isMobile && (
                  <PDFPreview file={pdfReport} scale={scale} handleSetPages={handleSetPages} />
                )}
                {isMobile && (
                  <MDBox mt={1} mb={1}>
                    <MDTypography variant="caption">
                      {t("please-use-a-web-browser-to-open-the-document")}
                    </MDTypography>
                  </MDBox>
                )}
              </>
            ) : showDetails ? (
              <MDBox style={{ display: "inline-flex" }}>
                <MDBox mb={2}>
                  <MDButton variant="outlined" color="primary" onClick={handleHideClickDetails}>
                    {t("back-to-details")}
                  </MDButton>
                  <MDBox my={3}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mb={2}
                    >
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={handleOpenMetadataModal}
                      >
                        {t("add-metadata")}
                      </MDButton>
                    </MDBox>
                    <Card>
                      <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {t("document-metadata")}
                        </MDTypography>
                      </MDBox>
                      <DataTable
                        table={structureDataForTable(
                          documentDetails.metadata.sort(
                            (a, b) => moment(b.createdAt) - moment(a.createdAt)
                          )
                        )}
                        entriesPerPage={false}
                        canSearch
                      />
                    </Card>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : (
              <>
                {uploadNotification && (
                  <MDAlert color="warning" dismissible mt={2}>
                    <MDTypography color="dark" variant="caption">
                      {t("please-upload-your-signature")}
                    </MDTypography>
                    <MDTypography
                      ml={1}
                      component={Link}
                      to="/profile/profile-overview#signature"
                      variant="button"
                      color="dark"
                      fontWeight="medium"
                      textGradient
                    >
                      {t("go-to-profile")}
                    </MDTypography>
                  </MDAlert>
                )}
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} md={12} lg={10}>
                    <Card>
                      <MDBox pt={2} px={2}>
                        <Header
                          document={documentDetails}
                          handleClick={handleClick}
                          handleOpenExtend={handleOpenExtend}
                          loading={loadingDetails}
                          ownerActions={showOwnerActions}
                          lookupUsers={lookupUsers}
                          pdfPages={pdfPages}
                        />
                      </MDBox>
                      <Divider />
                      <MDBox pt={1} pb={3} px={2}>
                        <MDBox mb={3}>
                          <OrderInfo
                            document={documentDetails}
                            handleDelete={handleDeleteFile}
                            handleDownload={handleDownloadReport}
                            handleRefresh={handleRefresh}
                            loading={loadingDetails}
                            lookupUsers={lookupUsers}
                            ownerActions={showOwnerActions}
                            disableSign={disableSign}
                            accessRead={accessRead}
                            handleClickDetails={handleClickDetails}
                          />
                        </MDBox>
                        <Divider />
                        <MDBox mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} xl={4}>
                              <OrderSummary document={documentDetails} loading={loadingDetails} />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={4}>
                              <Signatures
                                signatures={documentDetails?.signatures}
                                title={t("signatures")}
                                shadow={false}
                                loading={loadingDetails}
                                lookupUsers={lookupUsers}
                              />
                            </Grid>
                            <Grid item xs={12} xl={4}>
                              <Recipients
                                title={t("recipients")}
                                profiles={documentDetails?.accessRights}
                                shadow={false}
                                handleAction={handleDeleteAccess}
                                loading={loadingDetails}
                                fileDetails={documentDetails}
                                handleRefreshDoc={handleRefresh}
                                ownerActions={showOwnerActions}
                                address={SDCAddress}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </MDBox>

          <Footer />
          <MDSnackbar
            icon="notifications"
            title={title}
            color={variant}
            content={message}
            open={show}
            close={handleClose}
            dateTime=""
          />

          <Dialog open={openExtendEolModal} onClose={handleCloseExtend}>
            <DialogTitle>{t("extend-eol")}</DialogTitle>

            <DialogContent>
              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {`${t("current-eol-date-is")}: ${formatDate(documentDetails.endOfLife)}`}
                  </MDTypography>
                  <br />
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {`${t("extend-eol-date-to")}: ${formatDate(fileTime)}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={7} lg={7} xl={7} style={{ paddingTop: "0px" }}>
                  <MDInput
                    type="number"
                    variant="standard"
                    value={timeDuration}
                    onChange={handleChangeFileTime}
                    fullWidth
                    label={t("")}
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={5} lg={5} xl={5}>
                  <Autocomplete
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        paddingRight: "0px!important",
                      },
                    }}
                    defaultValue={t("weeks")}
                    disableClearable
                    value={timeValue}
                    onChange={handleChangeTimeValue}
                    options={[t("days"), t("weeks"), t("months"), t("years")]}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseExtend}>{t("cancel")}</MDButton>
              <MDButton variant="gradient" color="primary" size="small" onClick={handleExtendEol}>
                {t("extend-eol")}
              </MDButton>
            </DialogActions>
          </Dialog>

          <Dialog open={metadataModal} onClose={handleCloseMetadataModal}>
            <DialogTitle>{t("add-metadata")}</DialogTitle>

            <DialogContent>
              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <MDInput
                    type="text"
                    variant="standard"
                    value={packageMetadataName}
                    onChange={(e) => setPackageMetadataName(e.target.value)}
                    fullWidth
                    label={t("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    type="text"
                    variant="standard"
                    value={packageMetadata}
                    onChange={(e) => setPackageMetadata(e.target.value)}
                    fullWidth
                    label={t("metadata")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        paddingRight: "0px!important",
                      },
                    }}
                    defaultValue={ApiService.metadataTypes.string}
                    value={packageMetadataType}
                    disableClearable
                    onChange={(e, value) => setPackageMetadataType(value)}
                    options={[
                      ApiService.metadataTypes.json,
                      ApiService.metadataTypes.xml,
                      ApiService.metadataTypes.string,
                    ]}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseMetadataModal}>{t("cancel")}</MDButton>
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                onClick={handleAddPackageMetadata}
              >
                {t("add-metadata")}
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </DashboardLayout>
  );
}

export default DocumentDetails;
