/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { useNavigate, useLocation, Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/illustrations/unisot-bg.jpg";
import MDAlert from "components/MDAlert";

function Illustration() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [requireToken, setRequireToken] = useState(false);
  const [token2FA, setToken2FA] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [adLoginEnabled, setADLoginEnabled] = useState(false);

  useEffect(() => {
    ApiService.removeSessionToken();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const { adEnabled } = await ApiService.getPublicSystemConfig({}, signal);
        setADLoginEnabled(adEnabled);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/email-sign-in`);
    return url.toString();
  };

  const handleADSubmit = async (event) => {
    event.preventDefault();
    window.location.replace(`${ApiService.adLoginURL}?frontendUrl=${getFrontendUrl()}`);
  };

  const showErrorMessage = (messageContent) => {
    if (!requireToken) {
      if (messageContent === "Invalid 2FA token!") {
        setRequireToken(true);
        setMessage("2fa qrcode secret");
        return setToken2FA("");
      }
    } else {
      return setToken2FA("");
    }
    if (messageContent === "User does not exist!") {
      return setMessage("User doesnt exist");
    }
    if (messageContent === "Invalid password!") {
      return setMessage("Incorrect password");
    }
    return setMessage(messageContent);
  };

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      redirectPath: queryParams.get("redirectPath"),
    };
    return params;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError("Invalid email address");
      showErrorMessage(`${"Invalid information provided"} ${"invalid email"}`);
      return;
    }
    try {
      await ApiService.loginUser({ email, password, token2FA });
      const queryParams = getQueryParams();
      if (queryParams.redirectPath) {
        navigate(queryParams.redirectPath);
      } else {
        navigate("/");
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        {message.length > 0 && (
          <MDBox mb={2}>
            <MDTypography variant="caption" color="warning">
              {message}
            </MDTypography>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => {
              setMessage("");
              setEmail(e.target.value);
            }}
            error={emailError.length > 0}
            helperText={emailError}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => {
              setMessage("");
              setPassword(e.target.value);
            }}
          />
        </MDBox>
        {requireToken && (
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="2FA"
              fullWidth
              value={token2FA}
              onChange={(e) => {
                setMessage("");
                setToken2FA(e.target.value);
              }}
            />
          </MDBox>
        )}
        <MDTypography
          component={Link}
          to="/authentication/reset-password-email"
          variant="button"
          color="primary"
          fontWeight="medium"
          textGradient
        >
          Forgot password?
        </MDTypography>

        <MDAlert color="light" mt={2}>
          <MDTypography color="primary" variant="caption">
            Password-free sign in via
          </MDTypography>
          <MDTypography
            ml={1}
            component={Link}
            to="/authentication/email-sign-in"
            variant="button"
            color="primary"
            fontWeight="medium"
            textGradient
          >
            email
          </MDTypography>
        </MDAlert>
        {adLoginEnabled && (
          <MDAlert color="light" mt={2}>
            <MDTypography color="primary" variant="caption">
              Sign in with
            </MDTypography>
            <MDTypography
              ml={1}
              component={Link}
              onClick={handleADSubmit}
              to="/"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Microsoft
            </MDTypography>
          </MDAlert>
        )}
        <MDBox mt={2} mb={1}>
          <MDButton
            type="submit"
            variant="gradient"
            color="primary"
            size="large"
            fullWidth
            onClick={handleSubmit}
          >
            sign in
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
