/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, createRef } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import ApiService from "api/ApiService";
import Skeleton from "@mui/material/Skeleton";
import MDSnackbar from "components/MDSnackbar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatDate } from "api/utils";

function Header({ profile }) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const fileInput = createRef();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState({});
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const expDays = moment
    .utc(profile.createdAt)
    .add(ApiService.TRIAL_ACCOUNT_EXPIRY_TIME_SEC, "seconds");

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const getUserAvatar = async () => {
      try {
        const avatarRes = await ApiService.readUserDocument({
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: ApiService.documentTypes.avatar,
        });
        setImagePreviewUrl(`data:image/png;base64, ${avatarRes.contents}`);
      } catch (error) {
        console.error(error);
      }
    };
    getUserAvatar();
  }, [uploadResponse]);

  useEffect(() => {
    const uploadSignature = async () => {
      try {
        const resp = await ApiService.upsertUserImage({
          userId: profile._id,
          documentType: "AVATAR",
          encryptDocument: true,
          resizeImage: true,
          imageWidth: 400,
          imageHeight: 400,
          file: uploadedFile,
        });
        setUploadResponse(resp);
        setUploadedFile(null);
        setVariant("primary");
        setShow(true);
        setMessage(t("successfully-changed-picture"));
      } catch (e) {
        console.error(e);
        setVariant("error");
        setShow(true);
        setMessage(e.message);
      }
    };
    !(uploadedFile === null) && uploadSignature();
  }, [uploadedFile]);

  return (
    <>
      <Card id="profile">
        <MDBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <label htmlFor="files" style={{ display: "block", color: "#fff" }}>
                <MDAvatar src={imagePreviewUrl} alt="profile-image" size="xl" shadow="sm" />
              </label>
              <input
                style={{
                  display: "none",
                  cursor: "pointer",
                  height: "100%",
                  left: "0",
                  opacity: "0",
                  position: "absolute",
                  top: "0",
                  width: "100%",
                }}
                type="file"
                id="files"
                accept="image/png, image/gif, image/jpeg"
                ref={fileInput}
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  if (selectedFile) {
                    setUploadedFile(selectedFile);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {profile.name || (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "150px" }} />
                  )}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="medium">
                  {profile.email || (
                    <Skeleton variant="text" sx={{ fontSize: "inherit", width: "150px" }} />
                  )}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
              <MDBox
                display="flex"
                justifyContent={{ md: "flex-end" }}
                alignItems="center"
                lineHeight={1}
              >
                <MDTypography variant="caption" fontWeight="regular">
                  {profile.trial
                    ? `${t("trial-account")}, ${t("expires-at")} ${formatDate(expDays)}`
                    : ""}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <MDSnackbar
        icon="notifications"
        title={t("change-profile-picture")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default Header;
