/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ApiService from "api/ApiService";
import { Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import MDSnackbar from "components/MDSnackbar";
import bgImage from "assets/images/illustrations/unisot-bg.jpg";

function Illustration() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");

  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);

  useEffect(() => {
    ApiService.removeSessionToken();
  }, []);

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/reset-password`);
    return url.toString();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError("Invalid email address");
      return;
    }
    try {
      await ApiService.sendUserResetPasswordEmailLink({ email, rootUrl: getFrontendUrl() });
      setVariant("primary");
      setMessage("Check your email for password reset link");
      setShow(true);
    } catch (e) {
      setMessage(e.message);
      setVariant("warning");
      setShow(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <IllustrationLayout
      title="Reset password"
      description="Enter your email to reset password"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => {
              setMessage("");
              setEmail(e.target.value);
            }}
            error={emailError.length > 0}
            helperText={emailError}
          />
        </MDBox>

        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="primary"
            size="large"
            fullWidth
            onClick={handleSubmit}
          >
            Reset
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            <MDTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDSnackbar
        icon="notifications"
        title="Change password"
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </IllustrationLayout>
  );
}

export default Illustration;
