/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Skeleton from "@mui/material/Skeleton";

function TransactionSkelet() {
  return (
    <MDBox component="li" py={1} pr={2} mb={1}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton variant="outlined" color="primary" iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>expand_less</Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="medium" gutterBottom>
              <Skeleton variant="text" sx={{ width: "200px", fontSize: "inherit" }} />
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              <Skeleton variant="text" sx={{ width: "200px", fontSize: "inherit" }} />
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDTypography variant="button" color="primary" fontWeight="medium" textGradient>
          <Skeleton variant="text" sx={{ width: "50px", fontSize: "inherit" }} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default TransactionSkelet;
