/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import ApiService from "api/ApiService";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import { isObjectEmpty } from "api/utils";

function Authentication({ profile }) {
  const { t } = useTranslation();

  const [toggle2FA, setToggle2FA] = useState(false);
  const [token2FA, setToken2FA] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [qrCodeSecret2FA, setQrCodeSecret2FA] = useState(null);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [userProfileData, setUserProfileData] = useState(profile);

  const handleClose = () => {
    setShow(false);
  };

  const handleChangeToken = (event) => {
    setToken2FA(event.target.value);
  };

  const handleCloseEnableModal = () => {
    setOpenModal(false);
    setToggle2FA(false);
  };

  const handle2FAAction = async (toggleActive) => {
    if (!toggleActive) {
      try {
        await ApiService.disable2FA({
          userId: profile._id,
        });
        setShow(true);
        setVariant("info");
        setMessage(t("2fa-disabled"));
        setToggle2FA(false);
        setQrCodeSecret2FA(null);
        setToken2FA("");
      } catch (e) {
        console.error(e);
        setShow(true);
        setVariant("warning");
        setMessage(e.message);
      }

      try {
        const response = await ApiService.readUser();
        setUserProfileData(response);
        setToggle2FA(response.enabled2FA);
      } catch (e) {
        console.error(e);
      }
    }
    if (toggleActive) {
      try {
        const { qrCode } = await ApiService.generate2FASecret({
          userId: userProfileData._id,
        });
        setQrCodeSecret2FA(qrCode);
        setToggle2FA(true);
        setOpenModal(true);
      } catch (e) {
        console.error(e);
        setVariant("warning");
        setMessage(e.message);
        setShow(true);
      }
    }
    setToken2FA("");
  };

  const handleEnable2FASubmit = async () => {
    try {
      const responseData = await ApiService.enable2FA({
        token2FA,
      });
      setMessage(responseData.message);
      setShow(true);
      setVariant("info");
      setMessage(t("2fa-enabled"));
    } catch (e) {
      setToken2FA("");
      console.error(e);
      setShow(true);
      setVariant("warning");
      setMessage(e.message);
    }
    setOpenModal(false);
    try {
      const response = await ApiService.readUser();
      setUserProfileData(response);
      setToggle2FA(response.enabled2FA);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isObjectEmpty(profile)) {
      setToggle2FA(profile.enabled2FA);
    }
  }, [profile]);

  return (
    <>
      <Card id="2fa" sx={{ overflow: "visible" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography variant="h5">{t("security")}</MDTypography>
          <MDBadge
            variant="contained"
            color={toggle2FA ? "primary" : "light"}
            badgeContent={toggle2FA ? t("enabled") : t("disabled")}
            container
          />
        </MDBox>
        <MDBox p={3}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDTypography variant="body2" color="text">
              {t("two-factor-authentication")}
            </MDTypography>
            <MDBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Switch
                color="primary"
                checked={toggle2FA}
                onChange={(e) => handle2FAAction(e.target.checked)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Dialog open={openModal} onClose={handleCloseEnableModal}>
        <DialogTitle>{t("enable-2fa")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText>{t("scan-qr-code")}</DialogContentText>
          <img src={qrCodeSecret2FA} alt="2fa-qr-code-secret" />
          <TextField
            autoFocus
            margin="dense"
            id="token"
            label={t("2fa-confirmation-token")}
            fullWidth
            value={token2FA}
            variant="standard"
            onChange={handleChangeToken}
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="primary" onClick={handleCloseEnableModal}>
            {t("cancel")}
          </MDButton>
          <MDButton
            color="primary"
            disabled={token2FA.length === 0}
            onClick={handleEnable2FASubmit}
          >
            {t("enable")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title={t("security")}
        color={variant}
        content={message}
        open={show}
        close={handleClose}
        dateTime=""
      />
    </>
  );
}

export default Authentication;
