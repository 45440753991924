/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { formatDate, isObjectEmpty } from "api/utils";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

function Header({
  document,
  handleClick,
  handleOpenExtend,
  loading,
  ownerActions,
  lookupUsers,
  pdfPages,
}) {
  const { t } = useTranslation();

  const formatSize = (fileSize) => {
    const units = ["B", "KB", "MB", "GB", "TB"];

    let l = 0;
    let n = parseInt(fileSize, 10) || 0;

    // eslint-disable-next-line no-plusplus
    while (n >= 1000 && ++l) {
      // eslint-disable-next-line operator-assignment
      n = n / 1000;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 2)} ${units[l]}`;
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={12} md={8}>
        <MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {t("document-details")}
            </MDTypography>
          </MDBox>

          <MDTypography component="p" variant="button" color="text">
            {!loading &&
              !isObjectEmpty(document) &&
              (document.status === "EXPIRED" || document.status === "DELETED") && (
                <>
                  <span>{`${t("status")}: `}</span>
                  <b>{document.status}</b>
                </>
              )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("creator")}: `}</span>
                <b>{lookupUsers[document.sender]}</b>
              </>
            ) : (
              <>
                <span>{`${t("creator")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("upload-date")}: `}</span>
                <b>{formatDate(document.createdAt)}</b>
              </>
            ) : (
              <>
                <span>{`${t("upload-date")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("modified-date")}: `}</span>
                <b>{formatDate(document.updatedAt)}</b>
              </>
            ) : (
              <>
                <span>{`${t("modified-date")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("number-of-pages")}: `}</span>
                <b>{pdfPages}</b>
              </>
            ) : (
              <>
                <span>{`${t("number-of-pages")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("document-id")}: `}</span>
                <b style={{ wordBreak: "break-all" }}>{document._id}</b>
              </>
            ) : (
              <>
                <span>{`${t("document-id")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("document-hash")}: `}</span>
                <b style={{ wordBreak: "break-all" }}>{document.hash}</b>
              </>
            ) : (
              <>
                <span>{`${t("document-hash")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("eol-date")}: `}</span>
                <b>
                  {document.ttl === -1 ? t("file-stored-forever") : formatDate(document.endOfLife)}
                </b>
              </>
            ) : (
              <>
                <span>{`${t("eol-date")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text">
            {!loading && !isObjectEmpty(document) ? (
              <>
                <span>{`${t("size")}: `}</span>
                <b>{formatSize(document.fileSize)}</b>
              </>
            ) : (
              <>
                <span>{`${t("size")}: `}</span>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", fontSize: "0.875rem", display: "inline-block" }}
                />
              </>
            )}
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item sm={12} md={4}>
        <div style={{ float: "right" }}>
          {document.ttl !== -1 && (
            <MDButton
              variant="text"
              color="primary"
              onClick={handleOpenExtend}
              disabled={!ownerActions}
            >
              {t("extend-eol")}
            </MDButton>
          )}
          <MDButton
            variant="gradient"
            color="primary"
            onClick={handleClick}
            disabled={document.status === "EXPIRED" || document.status === "DELETED"}
          >
            {t("view-document")}
          </MDButton>
        </div>
      </Grid>
    </Grid>
  );
}

export default Header;
